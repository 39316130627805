import { LEAFLET_URL } from "../constants";

const allStoreData = [
  `
  <h1>LOTTE Mart</h1>
  <div>
    ·전단적용기간 : 2024. 10. 1(화) ~ 2024. 10. 16(수) < 신선식품 적용기간 : 2024. 10. 1(화) ~ 10. 9(수) >
    농림축산식품부, 한돈자조금관리위원회와 함께합니다.
    통큰혜택, 통큰할인, 통큰가격, 통큰선물 황금연휴 통큰딜 기간 10월 1일 화요일부터 10월 6일 일요일까지
    국내산 한돈 삼겹살/목심 (각 100g/냉장/국내산) ※제주점 제외 ※MAP(산소포장팩) 제외 ※조기 품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고 각1,890원
    킹크랩 (100g/러시아산) 롯데/KB국민/신한/NH농협/삼성카드 50% ※제주점 제외 ※산지 시황에 따라 조기 품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고 4,995원 ※기간 : 10/1(화) ~ 점별 재고 소진시까지
    New 한통가아득 치킨(1팩/국내산 계육) 롯데/KB국민/신한/NH농협/삼성카드 ※페이지 하단 카드할인 세부내용 참고 7,794원
    행복생생란(대란/30구/국산) 2판 이상 각1,000원 할인 2판 이상 구매시 1판당 각5,990원 ※1판 구매시 6,990원
    상생 대파(봉/국산) 롯데/신한/NH농협카드 400원 할인 ※1인 2봉 한정 ※ 일 한정수량 판매 상품으로 점포별 조기 마감될 수 있습니다
    봉지라면 13종(각 4개입) 1+1 ※오뚜기 마열라면 外 12종 ※동일 브랜드 內 동일 가격 간 교차구매 가능 각3,320~8,800원
    물/군/얇피/수제만두 25종 롯데/KB국민/신한/NH농협/삼성카드 2개 이상 50% (상품별 용량 상이) 해당카드로 2개 이상 구매시 1개당 각3,490~5,440원 ※1개 구매시 각 6,980~10,880원 ※기간 : 10/3(목) ~ 10/6(일) ※교차구매 가능 ※페이지 하단 카드할인 세부내용 참고
    반값 득템 초밥 30입 (1팩/원산지 별도표기) 14,995원
    좋은느낌 브랜드 세일(상품별 상이) 2개 이상 50% 좋은느낌 행사상품 3만원 이상 구매시 5천원 롯데상품권 증정 ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 '㉨'를 확인하세요 ※결제시 계산대에서 수령 ※점별 운영상품 상이 ※교차구매 가능(팬티라이너 제외) 2개 이상 구매시 1개당 각2,500~9,450원 ※1개 구매시 각 5,000~18,900원
    도루코 퓨어우드 IH 프라이팬 5종(20~30cm) ※L.POINT 회원에 한함 ※교차구매 가능 2개 이상 50% 2개 이상 구매시 1개당 각16,450~21,950원 ※1개 구매시 각 32,900~43,900원
    16입 두유 전품목(각 190ml×16입) 1+1 ※동일 시리즈 상품간 교차구매 가능 14,900~20,000원
    고객 여러분의 밥상물가를 지킬 10대 아이템 미션! 물가를 잡아라 롯데마트&롯데슈퍼가 물가안정에 앞장섭니다. 기간:10/1(화) ~10/16(수) (신선식품 적용은 10/1(화)~10/9(수), 9일간)
    민속 친한우 전품목 1+/1++ 등급(각 100g/냉장/국내산 한우고기) L.POINT 최대 50%
    프리미엄 파타고니아 연어 필렛(500g/냉장/칠레산) 19,900원
    2024 롯데마트가 매주 여러분의 경제적 소비생활을 지원합니다 이번주 핫 프라이스 육즙 가득 소고기와 향긋한 대파의 만남! 든든한 한끼 식사 뚝딱 완성 '특가' 산더미 대파 소불고기 (800g/냉장/원산지 별도표기) 롯데/KB국민/신한/NH농협/삼성카드 50%12,900원 ※기간 : 10/1(화) ~ 10/9(수) ※조기 품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고
    청도 홍시(4~6입/팩/국산) 2팩 이상 각 2,000원 할인 ※1팩 구매시 7,990원 2팩 이상 구매시 1팩당 각5,990원 ※1팩 구매시 7,990원
    공구핫딜 구운 아몬드/마카다미아 (800g/350g, 원산지 별도표기) 각9,990원
    삼진어묵 시원한 모듬어묵/쫄깃한 야채사각(456g/200g) 1+1 ※동일 상품에 한함 7,480/3,280원
    풀무원 서울라면/서울짜장 (각 4개입) 1+1 ※교차구매 가능 각5,450원
    떠먹는 요구르트 30종(각 80g×4입/각 85g×4입) 1+1 ※빙그레/풀무원/남양/매일/일동 外 ※동일 브랜드 內 동일 가격 간 교차구매 가능 각5,450원
    녹차원 꿀 유자차/레몬생강차 外 3종 (각 1kg) ※해당 상품에 한함 ※교차구매 가능 
    잘풀리는집 굿딜 3겹 화장지(30m×30롤) 롯데/KB국민/신한/NH농협/삼성카드 50% ※페이지 하단 카드할인 세부내용 참고 2개 이상 구매시 1개당 각13,900원 ※1개 구매시 27,800원
    테크 실내건조 베이킹+구연산 (각 3.4L, 일반/드럼) 1+1 ※교차구매 가능 각19,800원

    ※정상가란? 2024년 9월 10일(화) ~ 9월 30일(월) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함   
  </div>
  `,
  `
  <div>
    호주청정우 기획팩 8종 (품목별 상이/냉장/호주산) 2팩 이상 각 2,000원 할인 ※1팩 구매시 각 11,900원 ※교차구매 가능 2팩 이상 구매시 1팩당 각9,900원 ※1팩 구매시 각 11,900원 ※교차구매 가능
    호주청정우 척아이롤 100g/냉장/호주산 L.POINT 50% ※기간 : 10/1(화)~10/6(일) ※10/7(월)~10/9(수) : L.POINT 40% 할인 ※국거리/다짐육 제외
    참프레/정다운 훈제오리 슬라이스 (각 400g/냉장/원산지 별도표기) 1+1 각 14,990원
    수산물 구매금액의 1%가 지속가능한 어업활동에 기부됩니다
    완도 전복(특) (마리/냉장/국산) L.POINT 25% 3,450원
    제주 갈치(대) (3마리/해동/국산) 9,900원
    번들새우살 3종 (각 250g×2입/냉동/원산지 별도표기) L.POINT 40% 11,880원
    광어 연어 모둠회 (400g 내외/냉장/원산지 별도표기) L.POINT 30% 29,820원 ※입점점에 한함
    제주 황토밭 하우스 감귤 (1.5kg/박스/국산) 롯데/KB국민/신한/NH농협/삼성카드 1,000원 할인 11,990원 ※기간 : 10/1(화)~10/6(일) ※페이지 하단 카드할인 세부내용 참고
    제스프리 골드키위 (개/뉴질랜드산) 8개 구매시 9,920원 ※1개 구매시 1,490원
    사과대추(500g/팩/국산) L.POINT 2,000원 할인 7,990원 ※기간 : 10/1(화)~10/6(일) ※점별 운영상품 상이 ※조기 품절될 수 있습니다
    24년 김장준비! 절임배추&햇 태양초 사전예약 ※세부내용은 매장 고지물 참고
    해남 절임배추 (20kg/국산) L.POINT 5,000원 할인 롯데/신한/NH농협카드 5,000원 추가할인 29,900원 ※ 페이지 하단 카드할인 세부내용 참고 ※예약기간 : 10/1(화)~10/6(일) ※수령일 : 11/8(금)~12/21(토) ※한정물량 1만 박스 선착순 한정 ※물량 소진시 엘포인트 할인가 34,900원 적용
    괴산 절임배추 20kg/국산 35,900원 평창 절임배추 20kg/국산 59,900원 영월 절임배추 20kg/국산 54,900원 절임 알타리 5kg/국산 39,900원
    햇 건고추 사전예약! 햇 태양초 건고추/제분 (1.8kg/1.48kg, 국산) 각49,900원 ※예약기간 : 10/1(화)~10/16(수) ※매장에 다양한 상품이 안내되어 있습니다
    경기도식, 전라도식/강원도식 김치양념(각 3.5kg/원산지 별도표기) 39,900/45,900원
    GAP 완전미 고시히카리(10kg/국산) ※기간 : 10/1(화)~10/6(일) 34,900원
    양반 들기름향이 그윽한 식탁김/CJ 비비고 직화 참기름김(4.5g×20봉/4g×20봉, 원산지 별도표기) 각7,980원
    5무 훈제오리(1팩/원산지 별도표기) 10,990원 
    샌드위치 10종(1팩/원산지 별도표기) ※듬뿍 햄치즈 샌드위치 外 9종 각5,990원
    해물 양장피(1팩/원산지 별도표기) L.POINT 20% 15,992원
    반찬 골라담기(4팩/원산지 별도표기) 3+1 10,000원 ※미찬, 비움, 도시곳간, 무명찬반, 웰빙앤찬, 초이스쿡 입점점에 한함 ※팩당 4,000원 이하 상품 한정
    부드러운 호밀빵(8입/1팩/원산지 별도표기) 1,500원 할인 5,900원※ 베이커리팩토리/쁘띠르뽀미에 입점점에 한함
    하나사면 하나 더 1+1
    동원 통그릴 부어스트(500g) 1+1 8,980원
    유부초밥 4종(상품별 상이) 1+1 5,280~5,680원 ※기간 : 10/3(목) ~ 10/6(일) ※동일 상품에 한함
    오뚜기 스파이시마요 포테이토피자(370g) 1+1 9,980원 ※기간 : 10/1(화) ~ 10/6(일)
    마니커 소금구이 통날개구이(400g) 1+1 11,980원
    오뚜기 파스타소스 12종 (상품별 규격 상이) 1+1 5,980~6,980원 ※기간 : 10/1(화) ~ 10/6(일) ※동일 가격 상품간 교차구매 가능
    하림 더미식 상온 국물요리 6종 (부대찌개 外 5종, 각 350g) 1+1 각5,980원 ※교차구매 가능
    사조 자연산 골뱅이(400g) 1+1 12,480원 ※기간 : 10/1(화) ~ 10/6(일)
    환절기 기능성 유아음료 16종 (상품별 규격 상이) 1+1 1,500~4,900원 ※동일 브랜드/동일 가격 교차구매 가능
    덴마크 인포켓 치즈 (200g/봉) 1+1 14,000원 ※기간 : 10/1(화)~10/6(일)
    서울 유기농 우유(700ml) 1+1 4,980원 ※기간 : 10/1(화)~10/6(일)
    탄산음료 대형 PET 전품목 (상품별 규격 상이) 최대 50% 1+1 각1,190~3,680원 ※상품별 행사 상이 ※기간 : 10/1(화)~10/6(일) ※동일 시리즈 간 교차구매 가능
    한진 야채 크래커/우유 크래커(각 240g) 1+1 각7,980원 ※기간 : 10/1(화) ~ 10/6(일) ※교차구매 가능
    삼립 미니꿀호떡 2종(각 192g, 오리지널/옥수수) 1+1 각3,480원 ※교차구매 가능
    자임 하루하나 유기농 레몬즙(20g×14입) 1+1 15,900원
    2개 이상 구매시 더 싸게
    CJ 비비고 통새우만두/쌀떡볶이(상품별 규격 상이) 2개 이상 각 1,000원 할인 ※1개 구매시 각 6,980원 ※교차구매 가능 2개 이상 구매시 1개당 각5,980원 ※1개 구매시 각 6,980원 ※교차구매 가능
    풀무원 노엣지피자/핫도그 6종(상품별 상이) 2개 이상 각 3,980원 할인 ※기간 : 10/1(화) ~ 10/6(일)※1개 구매시 각 8,980원 ※교차구매 가능 2개 이상 구매시 1개당 각 5,000원 ※1개 구매시 각 8,980원 ※교차구매 가능
    냉장 밀키트 6종(상품별상이) 2개 이상 40% ※기간 : 10/3(목) ~ 10/6(일) 2개 이상 구매시 1개당 각5,988~8,940원 ※1개 구매시 각 9,980~14,900원 ※교차구매 가능
    농심 봉지라면 전품목 42종(상품별 규격 상이) 2개 이상 10% ※올리브 짜파게티 5개입 外 41종 2개 이상 구매시 1개당 각1,422~14,040원 ※1개 구매시 각 1,580~15,600원 ※교차구매 가능
    샘표 고추장/된장/쌈장 전품목 11종(상품별 용량 상이) 2개 이상 50% 2개 이상 구매시 1개당 각1,245~16,490원 ※1개 구매시 각 2,490~32,980원 ※교차구매 가능
    빙그레 벨치즈 8종 (상품별 용량 상이) 2개 이상 50% ※빙그레 래핑카우 플레인 外 7종 ※기간 : 10/1(화) ~ 10/6(일) 2개 이상 구매시 1개당 각2,990~5,290원 ※1개 구매시 각 5,980~10,580원 ※교차구매 가능
    파스퇴르 위편한/속편한 요구르트(각 150ml×4입) 2개 이상 50% ※기간 : 10/1(화) ~ 10/6(일) 2개 이상 구매시 1개당 각3,340원 ※1개 구매시 각 6,680원 ※교차구매 가능
    켈로그 그래놀라 5종(상품별 용량 상이) 2개 이상 10% ※켈로그 크랜베리 그래놀라 外 2개 이상 구매시 1개당 각5,922원 ※1개 구매시 각 6,580원 ※교차구매 가능
    제주 삼다수 그린(2L×6입/500ml×20입) 2개 이상 30% ※기간 : 10/1(화)~10/6(일) ※제주점 제외 2개 이상 구매시 1개당 각4,536/6,720원 ※1개 구매시 각 6,480/9,600원 ※교차구매 가능

    ※정상가란? 2024년 9월 10일(화) ~ 9월 30일(월) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
    </div>
  `,
  `
  <div>
    공구핫딜 하림 치킨너겟 (480g+300g) 9,980원
    공구핫딜 고래사 꼬치어묵(10+2입) 6,940원
    공구핫딜 고래사 5가지 야채듬뿍 사각어묵(450g) 4,330원
    공구핫딜 한성 와일드 크래미 (180g×3) 6,980원
    공구핫딜 롯데 비엔나 소시지(600g) 7,980원
    CJ 가쓰오 우동(4인) 8,980원
    롯데 식사이론 만두 3종(각 700g) ※대파고기/단호박닭가슴살/메밀부추 각8,480원
    동원 살코기 마일드 참치기획 (90g×3입+90g×3입) L.POINT 4,500원 할인 9,980원
    하인즈 파스타소스 5종(각 350g) L.POINT 40% ※베이컨 토마토 外 4종 ※점별 운영상품 상이 각3,588~3,888원
    동원 소와나무 순수우유 기획 (900ml×2입) ※기간 : 10/1(화)~10/6(일) 3,880원
    공구핫딜 칠성사이다(190ml×30입)/공구핫딜 코카콜라(215ml×30입) 14,490/18,360원 칠성사이다 개당 483원! 코카콜라 개당 612원!
    공구핫딜 팜앤홈 제주감귤 (1.2L×4입) 5,880원
    환타 오렌지,파인애플(각 1L)/파워에이드, 토레타, 토레타제로(각 900ml) 최대1,400원 할인 각1,000원
    롯데마트 단독 매일 바이오 그릭×그래놀라 기획(400g×2입) 8,980원
    공구핫딜 롯데 초코파이(40g×27입) ※점별 운영상품 상이 8,880원
    켈로그 프링글스 미니캔 번들(636g/오리지날 6입, 양파맛 6입) ※점별 운영상품 상이 11,980원
    켈로그 브롤스타즈 기획팩(1kg) ※점별 운영상품 상이 11,980원
    롯데 부여알밤 과자/디저트빵 8종 (상품별 용량 상이) ※롯데 빈츠 부여알밤 外 ※점별 운영상품 상이 각1,580~7,980원
    슈퍼너츠 피넛버터 2종 (각 460g, 스무스/크런치) 각2,500원 할인 각 11,900원
    공구핫딜 뉴트리원 이뮨플러스(193.5g) 7+2 기획팩 13,900원
    엘지생활건강 3만원 이상 구매시 5,000원 롯데상품권 증정 ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※결제시 계산대에서 수령 ※구분자 "ⓩ"을 확인하세요 ※각 브랜드별 구분자를 확인하세요 ※일부품목 상품권 증정 행사 제외
    
    2개 이상 50%
    피지 액체세제 9종(상품별 상이)
    ※디나자임/탈취실내건조/어두운의류용
    ※기간 : 10/1(화)~10/6(일)
    2개 이상 구매시 1개당 각9,450~9,900원
    ※1개 구매시 각18,900~19,800원 ※교차구매 가능

    2개 이상 50%
    아우라 퍼퓸 리필 (각 1.7L, 로즈/베르가못)
    2개 이상 구매시 1개당 각4,950원
    ※1개 구매시 각9,900원 ※교차구매 가능

    2개 이상 50%
    페리오 토탈7 인텐스 치약 2종
    (리프레쉬쿨링/프리징쿨링)
    ※점별 운영상품 상이
    2개 이상 구매시 1개당 각6,990원
    ※1개 구매시 각13,980원 ※교차구매 가능

    2개 이상 50%
    페리오 이지클리닉 칫솔 2종 (초극세모/탄력모) ※점별 운영상품 상이
    2개 이상 구매시 1개당 각4,450원
    ※1개 구매시 각8,900원 ※교차구매 가능

    2개 이상 50%
    엘지생활건강
    클렌징 폼 전품목(상품별 상이) 
    ※점별 운영상품 상이
    ※일부 재고소진 품목 제외
    ※일부품목 상품권 증정 행사 제외
    2개 이상 구매시 1개당 각3,950~6,900원
    ※1개 구매시 각7,900~13,800원 ※교차구매 가능

    2개 이상 50%
    온더바디 바디워시 전품목(상품별 상이) ※
    점별 운영상품 상이
    ※일부품목 상품권 증정 행사 제외
    ※기간 : 10/1(화)~10/6(일) 
    2개 이상 구매시 1개당 각5,450~8,250원
    ※1개 구매시 각10,900~16,500원 ※교차구매 가능

    2개 이상 50%
    엘라스틴 전품목(상품별 상이)
    ※점별 운영상품 상이 ※기간 : 10/1(화)~10/6(일)
    2개 이상 구매시 1개당 각5,450~11,000원
    ※1개 구매시 각10,900~22,000원 ※교차구매 가능

    2개 이상 50%
    리엔 보윤 샴푸/컨디셔너 3종(각 1,000ml) 
    ※점별 운영상품 상이
    각9,540원

    공구핫딜 순샘 레몬 (2L+2L) 7,900원
    공구핫딜 퍼실 파워젤/실내건조 기획(각 2.7L+2.2L) 각24,990원
    공구핫딜 고농축피죤 기획(파우더라일락, 2L+2L) 13,900원
    공구핫딜 케라시스 비듬케어 쿨링샴푸(각 1,000ml, 딥클린/가려움완화) 각5,900원
    공구핫딜 샤워메이트 플라워퍼퓸 바디워시 옐로우(프리지아&쟈스민향 1,200ml) 4,900원
    물가안정 인기 생필품
    피지오겔/바이오더마/세타필 더마 스킨케어 5종(상품별 상이) 롯데/KB국민/신한/NH농협/삼성카드 10% ※페이지 하단 카드할인 세부내용 참고 ※점별 운영상품 상이 각17,010~24,219원
    도브 바디로션 3종(각 400ml) 2개 이상50% ※점별 운영상품 상이 ※너리시드/셔플바운스/라이트 2개 이상 구매시 1개당 각7,950원 ※1개 구매시 각15,900원 ※교차구매 가능
    존슨즈 베이비로션 3종 (상품별 상이) 2개 이상50% ※점별 운영상품 상이 ※핑크 500ml+200ml, 화이트/베드타임 각 500ml 2개 이상 구매시 1개당 각8,950~10,950원 ※1개 구매시 각17,900~21,900원 ※교차구매 가능

    자연퐁 전품목(상품별 상이) 롯데/KB국민/신한/NH농협/삼성카드 2개 이상 60%
    ※페이지 하단 카드할인 세부내용 참고
    ※일부품목 제외 ※기간 : 10/1(화)~10/6(일)
    해당카드로 2개 이상 구매시 1개당 각3,160~7,920원
    ※1개 구매시 각7,900~19,800원 ※교차구매 가능

    깨끗한나라 페퍼민트 물티슈(캡형 70매×6입)  2개 이상50%
    ※기간 : 10/1(화)~10/6(일)
    2개 이상 구매시 1개당 각6,450원
    ※1개 구매시 12,900원

    모나리자 행사상품 2만 5천원 이상 구매시
    5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령 ※구분자 "㉤"을 확인하세요

    모나리자 코튼화이트 (30m×30롤) 2개 이상 50%
    2개 이상 구매시 1개당 ※1개 구매시 30,800원
    ※1개 구매시 30,800원

    하기스 행사상품 10만원 이상 구매시
    1만원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령 ※구분자 "㉡"을 확인하세요

    하기스 맥스드라이/매직컴포트/네이처메이드 29종(상품별 상이) 2개 이상 50%
    ※점별 운영상품 상이 ※기간 : 10/1(화)~10/9(수)
    2개 이상 구매시 1개당
    각15,300~26,400원
    ※1개 구매시 각30,600~52,800원 ※교차구매 가능

    크리넥스 마이비데 물티슈 전품목 (상품별 상이) L.POINT 할인 ※점별 운영상품 상이 ※L.POINT회원에 한함 각1,813~10,430원
    순수한면 제로 울트라날개 기획 (중형 16매×3입+대형 14매) 4,000원 할인 ※기간 : 10/1(화)~10/6(일) 9,900원

    ※정상가란? 2024년 9월 10일(화) ~ 9월 30일(월) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    가을특가 리빙 플렉스

    40%
    에브리데이쿡 프라이팬 4종/
    뉴이모션 프라이팬 5종(상품별 상이)
    18,480~39,480원

    최대 20%
    3M 다목적 수세미/
    제로스크레치 수세미(각 10입)
    ※3M 상품권 증정행사 미해당 품목
    6,390/8,790원

    공구핫딜 실속형 종이컵(600P)/
    테이크아웃 종이컵(300P)
    9,900/15,900원

    1,790원 할인
    더좋은 부탄가스(4입)
    5,000원

    20%
    오늘좋은 세라믹
    팬냄비(상품별 상이)
    20,720~39,120원

    2입 기획
    락앤락 김치통 2P기획 4종
    (2.6L/3.6L/4.5L/8L)
    각9,900~21,000원

    30%
    오늘좋은 극세사 누빔패드
    (싱글 110×200cm/퀸 150×220cm)
    ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이
    24,430~27,930원

    2개 이상 50%
    오늘좋은 세탁이 편리한 베개
    (40×60cm/50×70cm)
    2개 이상 구매시 1개당 각10,950/14,950원
    ※1개 구매시 각 21,900/29,900원 ※교차구매 가능

    20%
    오늘좋은
    메모리폼 토퍼
    (S/Q) ※베이직/더 두꺼운
    79,200~151,920원

    30%
    룸바이홈 럭셔리 세면타월
    (각 4P) ※네이비/화이트/그레이
    각18,130원

    10%
    디클린 정수필터 4종(상품별 상이)
    7,560~18,610원

    2개 이상 50%
    룸바이홈 호텔 발매트
    (각 45×65cm)
    ※챠콜/베이지/그레이 ※입점점에 한함
    ※해당상품에 한함 ※점별 재고 상이
    ※1개 구매시 각 13,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각6,950원
    ※1개 구매시 각 13,900원 ※교차구매 가능

    3M 해당상품
    2만5천원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※'도와드리겠습니다'센터 증정
    ※구분자 "M"를 확인하세요

    3M 올터치 막대걸레
    (표준형/대형) ※입점점에 한함
    ※해당상품에 한함 ※점별 재고 상이
    26,290/29,590원

    최대 50%
    아이리스 시스템 수납함 2종/
    우드탑 서랍장 2종(상품별 상이)
    7,450~76,300원

    최대 30%
    오스람/레드밴스 전구조명기구
    (전구/형광등/등기구/취침등)
    ※점별 재고상이 ※한정수량으로 조기소진될 수 있습니다
    3,700~31,820원

    엑스가드 레저소품(상품별 상이)
    ※귀걸이 멀티프, 트레이닝 장갑 外
    5,900~17,900원

    오늘좋은 히트 동내의(상품별 상이)
    7,900~15,900원

    확장지퍼로 수납력 확대!
    더블 4휠 바퀴 장착으로
    이동시 저소음!
    트래커 여행가방(기내용 16/20인치, 화물용 24/28인치)
    59,000~99,000원

    최대25,000원 할인
    허밋 원터치 텐트
    (2~3인용/3~4인용) ※입점점에 한함
    39,900/49,900원

    토이저러스 닌텐도 스위치 10종 L.POINT 1만원 할인

    L.POINT 각 10,000원
    스위치 슈퍼마리오브라더스
    원더 外 9종(전체 이용가)
    ※입점점에 한함 ※점별 재고 상이
    각54,800원

    위시캣 방영 신상품 출시 20종
    (상품별 상이) ※입점점에 한함 ※점별 재고 상이
    12,900~95,900원

    위스카스 콜리올리 반려동물 식/용품 해당 상품20%

    20%
    마즈 위스카스 사료(상품별 상이)
    960~20,720원

    20%
    콜리올리
    벤토나이트
    모래 2종(라벤더향/무향)
    각7,120원

    세계맥주 6캔 12,000원 골라담기
    세계맥주 골라담기
    (38종/상품별 용량 상이) 6캔 구매시 12,000원
    ※1캔 구매시 3,000원 ※4캔 구매시 9,000원

    코젤 보드게임 패키지
    (500ml×6)
    15,980원

    L.POINT 각 9,000원
    몬테스 알파
    까베르네 소비뇽/
    메를로(각 750ml)
    각 28,800원

    L.POINT 각 9,000원
    버팔로트레이스
    (750ml)
    45,900원

    요리하다
    온가족 먹거리 추천!

    요리하다 허니
    바사삭치킨(375g)
    5,990원

    1,000원 할인
    요리하다×두끼 즉석떡볶이/
    마라떡볶이(560g/520g)
    각4,990원

    L.POINT 2,000원
    요리하다 꽃게탕(660g)
    11,900원

    요리하다 정통 가쓰오우동
    (484g)
    4,990원

    오늘 가장 좋은 선택 BEST MD'S PICK
    좋은 상품을 최적의 가격에, 롯데전문MD.롯데중앙연구소 PB개발 노하우로 만든 대표 브랜드

    500원 할인
    오늘좋은 오리지널
    &카라멜 팝콘(280g)
    2,990원

    오늘좋은 버터갈릭 새우칩/
    블랙패퍼쉬림프 새우칩(각 155g)
    각2,000원

    오늘좋은 먹태칩(50g)/
    옥수수 똘뱅이(195g)
    1,000/2,000원

    오늘좋은×고래사
    종합모둠어묵탕(700g)
    6,990원

    ※정상가란? 2024년 9월 10일(화) ~ 9월 30일(월) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함


  </div>
  `,
];

const zettaData = [
  `
  <h1>LOTTE Mart ZETTAPLEX</h1>
  
  <div>
    ·전단적용기간 : 2024. 10. 1(화) ~ 2024. 10. 16(수) < 신선식품 적용기간 : 2024. 10. 1(화) ~ 10. 9(수) >
    농림축산식품부, 한돈자조금관리위원회와 함께합니다.
    통큰혜택, 통큰할인, 통큰가격, 통큰선물 황금연휴 통큰딜 기간 10월 1일 화요일부터 10월 6일 일요일까지
    국내산 한돈 삼겹살/목심 (각 100g/냉장/국내산) ※제주점 제외 ※MAP(산소포장팩) 제외 ※조기 품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고 각1,890원
    킹크랩 (100g/러시아산) 롯데/KB국민/신한/NH농협/삼성카드 50% ※제주점 제외 ※산지 시황에 따라 조기 품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고 4,995원 ※기간 : 10/1(화) ~ 점별 재고 소진시까지
    New 한통가아득 치킨(1팩/국내산 계육) 롯데/KB국민/신한/NH농협/삼성카드 ※페이지 하단 카드할인 세부내용 참고 7,794원
    행복생생란(대란/30구/국산) 2판 이상 각1,000원 할인 2판 이상 구매시 1판당 각5,990원 ※1판 구매시 6,990원
    상생 대파(봉/국산) 롯데/신한/NH농협카드 400원 할인 ※1인 2봉 한정 ※ 일 한정수량 판매 상품으로 점포별 조기 마감될 수 있습니다
    봉지라면 13종(각 4개입) 1+1 ※오뚜기 마열라면 外 12종 ※동일 브랜드 內 동일 가격 간 교차구매 가능 각3,320~8,800원
    물/군/얇피/수제만두 25종 롯데/KB국민/신한/NH농협/삼성카드 2개 이상 50% (상품별 용량 상이) 해당카드로 2개 이상 구매시 1개당 각3,490~5,440원 ※1개 구매시 각 6,980~10,880원 ※기간 : 10/3(목) ~ 10/6(일) ※교차구매 가능 ※페이지 하단 카드할인 세부내용 참고
    반값 득템 초밥 30입 (1팩/원산지 별도표기) 14,995원
    좋은느낌 브랜드 세일(상품별 상이) 2개 이상 50% 좋은느낌 행사상품 3만원 이상 구매시 5천원 롯데상품권 증정 ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 '㉨'를 확인하세요 ※결제시 계산대에서 수령 ※점별 운영상품 상이 ※교차구매 가능(팬티라이너 제외) 2개 이상 구매시 1개당 각2,500~9,450원 ※1개 구매시 각 5,000~18,900원
    도루코 퓨어우드 IH 프라이팬 5종(20~30cm) ※L.POINT 회원에 한함 ※교차구매 가능 2개 이상 50% 2개 이상 구매시 1개당 각16,450~21,950원 ※1개 구매시 각 32,900~43,900원
    16입 두유 전품목(각 190ml×16입) 1+1 ※동일 시리즈 상품간 교차구매 가능 14,900~20,000원
    고객 여러분의 밥상물가를 지킬 10대 아이템 미션! 물가를 잡아라 롯데마트&롯데슈퍼가 물가안정에 앞장섭니다. 기간:10/1(화) ~10/16(수) (신선식품 적용은 10/1(화)~10/9(수), 9일간)
    민속 친한우 전품목 1+/1++ 등급(각 100g/냉장/국내산 한우고기) L.POINT 최대 50%
    프리미엄 파타고니아 연어 필렛(500g/냉장/칠레산) 19,900원
    2024 롯데마트가 매주 여러분의 경제적 소비생활을 지원합니다 이번주 핫 프라이스 육즙 가득 소고기와 향긋한 대파의 만남! 든든한 한끼 식사 뚝딱 완성 '특가' 산더미 대파 소불고기 (800g/냉장/원산지 별도표기) 롯데/KB국민/신한/NH농협/삼성카드 50%12,900원 ※기간 : 10/1(화) ~ 10/9(수) ※조기 품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고
    청도 홍시(4~6입/팩/국산) 2팩 이상 각 2,000원 할인 ※1팩 구매시 7,990원 2팩 이상 구매시 1팩당 각5,990원 ※1팩 구매시 7,990원
    공구핫딜 구운 아몬드/마카다미아 (800g/350g, 원산지 별도표기) 각9,990원
    삼진어묵 시원한 모듬어묵/쫄깃한 야채사각(456g/200g) 1+1 ※동일 상품에 한함 7,480/3,280원
    풀무원 서울라면/서울짜장 (각 4개입) 1+1 ※교차구매 가능 각5,450원
    떠먹는 요구르트 30종(각 80g×4입/각 85g×4입) 1+1 ※빙그레/풀무원/남양/매일/일동 外 ※동일 브랜드 內 동일 가격 간 교차구매 가능 각5,450원
    녹차원 꿀 유자차/레몬생강차 外 3종 (각 1kg) ※해당 상품에 한함 ※교차구매 가능 
    잘풀리는집 굿딜 3겹 화장지(30m×30롤) 롯데/KB국민/신한/NH농협/삼성카드 50% ※페이지 하단 카드할인 세부내용 참고 2개 이상 구매시 1개당 각13,900원 ※1개 구매시 27,800원
    테크 실내건조 베이킹+구연산 (각 3.4L, 일반/드럼) 1+1 ※교차구매 가능 각19,800원

    ※정상가란? 2024년 9월 10일(화) ~ 9월 30일(월) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함   
  </div>
  `,
  `
  <div>
    호주청정우 기획팩 8종 (품목별 상이/냉장/호주산) 2팩 이상 각 2,000원 할인 ※1팩 구매시 각 11,900원 ※교차구매 가능 2팩 이상 구매시 1팩당 각9,900원 ※1팩 구매시 각 11,900원 ※교차구매 가능
    호주청정우 척아이롤 100g/냉장/호주산 L.POINT 50% ※기간 : 10/1(화)~10/6(일) ※10/7(월)~10/9(수) : L.POINT 40% 할인 ※국거리/다짐육 제외
    참프레/정다운 훈제오리 슬라이스 (각 400g/냉장/원산지 별도표기) 1+1 각 14,990원
    수산물 구매금액의 1%가 지속가능한 어업활동에 기부됩니다
    완도 전복(특) (마리/냉장/국산) L.POINT 25% 3,450원
    제주 갈치(대) (3마리/해동/국산) 9,900원
    번들새우살 3종 (각 250g×2입/냉동/원산지 별도표기) L.POINT 40% 11,880원
    광어 연어 모둠회 (400g 내외/냉장/원산지 별도표기) L.POINT 30% 29,820원 ※입점점에 한함
    제주 황토밭 하우스 감귤 (1.5kg/박스/국산) 롯데/KB국민/신한/NH농협/삼성카드 1,000원 할인 11,990원 ※기간 : 10/1(화)~10/6(일) ※페이지 하단 카드할인 세부내용 참고
    제스프리 골드키위 (개/뉴질랜드산) 8개 구매시 9,920원 ※1개 구매시 1,490원
    사과대추(500g/팩/국산) L.POINT 2,000원 할인 7,990원 ※기간 : 10/1(화)~10/6(일) ※점별 운영상품 상이 ※조기 품절될 수 있습니다
    24년 김장준비! 절임배추&햇 태양초 사전예약 ※세부내용은 매장 고지물 참고
    해남 절임배추 (20kg/국산) L.POINT 5,000원 할인 롯데/신한/NH농협카드 5,000원 추가할인 29,900원 ※ 페이지 하단 카드할인 세부내용 참고 ※예약기간 : 10/1(화)~10/6(일) ※수령일 : 11/8(금)~12/21(토) ※한정물량 1만 박스 선착순 한정 ※물량 소진시 엘포인트 할인가 34,900원 적용
    괴산 절임배추 20kg/국산 35,900원 평창 절임배추 20kg/국산 59,900원 영월 절임배추 20kg/국산 54,900원 절임 알타리 5kg/국산 39,900원
    햇 건고추 사전예약! 햇 태양초 건고추/제분 (1.8kg/1.48kg, 국산) 각49,900원 ※예약기간 : 10/1(화)~10/16(수) ※매장에 다양한 상품이 안내되어 있습니다
    경기도식, 전라도식/강원도식 김치양념(각 3.5kg/원산지 별도표기) 39,900/45,900원
    GAP 완전미 고시히카리(10kg/국산) ※기간 : 10/1(화)~10/6(일) 34,900원
    양반 들기름향이 그윽한 식탁김/CJ 비비고 직화 참기름김(4.5g×20봉/4g×20봉, 원산지 별도표기) 각7,980원
    5무 훈제오리(1팩/원산지 별도표기) 10,990원 
    샌드위치 10종(1팩/원산지 별도표기) ※듬뿍 햄치즈 샌드위치 外 9종 각5,990원
    해물 양장피(1팩/원산지 별도표기) L.POINT 20% 15,992원
    반찬 골라담기(4팩/원산지 별도표기) 3+1 10,000원 ※미찬, 비움, 도시곳간, 무명찬반, 웰빙앤찬, 초이스쿡 입점점에 한함 ※팩당 4,000원 이하 상품 한정
    부드러운 호밀빵(8입/1팩/원산지 별도표기) 1,500원 할인 5,900원※ 베이커리팩토리/쁘띠르뽀미에 입점점에 한함
    하나사면 하나 더 1+1
    동원 통그릴 부어스트(500g) 1+1 8,980원
    유부초밥 4종(상품별 상이) 1+1 5,280~5,680원 ※기간 : 10/3(목) ~ 10/6(일) ※동일 상품에 한함
    오뚜기 스파이시마요 포테이토피자(370g) 1+1 9,980원 ※기간 : 10/1(화) ~ 10/6(일)
    마니커 소금구이 통날개구이(400g) 1+1 11,980원
    오뚜기 파스타소스 12종 (상품별 규격 상이) 1+1 5,980~6,980원 ※기간 : 10/1(화) ~ 10/6(일) ※동일 가격 상품간 교차구매 가능
    하림 더미식 상온 국물요리 6종 (부대찌개 外 5종, 각 350g) 1+1 각5,980원 ※교차구매 가능
    사조 자연산 골뱅이(400g) 1+1 12,480원 ※기간 : 10/1(화) ~ 10/6(일)
    환절기 기능성 유아음료 16종 (상품별 규격 상이) 1+1 1,500~4,900원 ※동일 브랜드/동일 가격 교차구매 가능
    덴마크 인포켓 치즈 (200g/봉) 1+1 14,000원 ※기간 : 10/1(화)~10/6(일)
    서울 유기농 우유(700ml) 1+1 4,980원 ※기간 : 10/1(화)~10/6(일)
    탄산음료 대형 PET 전품목 (상품별 규격 상이) 최대 50% 1+1 각1,190~3,680원 ※상품별 행사 상이 ※기간 : 10/1(화)~10/6(일) ※동일 시리즈 간 교차구매 가능
    한진 야채 크래커/우유 크래커(각 240g) 1+1 각7,980원 ※기간 : 10/1(화) ~ 10/6(일) ※교차구매 가능
    삼립 미니꿀호떡 2종(각 192g, 오리지널/옥수수) 1+1 각3,480원 ※교차구매 가능
    자임 하루하나 유기농 레몬즙(20g×14입) 1+1 15,900원
    2개 이상 구매시 더 싸게
    CJ 비비고 통새우만두/쌀떡볶이(상품별 규격 상이) 2개 이상 각 1,000원 할인 ※1개 구매시 각 6,980원 ※교차구매 가능 2개 이상 구매시 1개당 각5,980원 ※1개 구매시 각 6,980원 ※교차구매 가능
    풀무원 노엣지피자/핫도그 6종(상품별 상이) 2개 이상 각 3,980원 할인 ※기간 : 10/1(화) ~ 10/6(일)※1개 구매시 각 8,980원 ※교차구매 가능 2개 이상 구매시 1개당 각 5,000원 ※1개 구매시 각 8,980원 ※교차구매 가능
    냉장 밀키트 6종(상품별상이) 2개 이상 40% ※기간 : 10/3(목) ~ 10/6(일) 2개 이상 구매시 1개당 각5,988~8,940원 ※1개 구매시 각 9,980~14,900원 ※교차구매 가능
    농심 봉지라면 전품목 42종(상품별 규격 상이) 2개 이상 10% ※올리브 짜파게티 5개입 外 41종 2개 이상 구매시 1개당 각1,422~14,040원 ※1개 구매시 각 1,580~15,600원 ※교차구매 가능
    샘표 고추장/된장/쌈장 전품목 11종(상품별 용량 상이) 2개 이상 50% 2개 이상 구매시 1개당 각1,245~16,490원 ※1개 구매시 각 2,490~32,980원 ※교차구매 가능
    빙그레 벨치즈 8종 (상품별 용량 상이) 2개 이상 50% ※빙그레 래핑카우 플레인 外 7종 ※기간 : 10/1(화) ~ 10/6(일) 2개 이상 구매시 1개당 각2,990~5,290원 ※1개 구매시 각 5,980~10,580원 ※교차구매 가능
    파스퇴르 위편한/속편한 요구르트(각 150ml×4입) 2개 이상 50% ※기간 : 10/1(화) ~ 10/6(일) 2개 이상 구매시 1개당 각3,340원 ※1개 구매시 각 6,680원 ※교차구매 가능
    켈로그 그래놀라 5종(상품별 용량 상이) 2개 이상 10% ※켈로그 크랜베리 그래놀라 外 2개 이상 구매시 1개당 각5,922원 ※1개 구매시 각 6,580원 ※교차구매 가능
    제주 삼다수 그린(2L×6입/500ml×20입) 2개 이상 30% ※기간 : 10/1(화)~10/6(일) ※제주점 제외 2개 이상 구매시 1개당 각4,536/6,720원 ※1개 구매시 각 6,480/9,600원 ※교차구매 가능

    ※정상가란? 2024년 9월 10일(화) ~ 9월 30일(월) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
    </div>
  `,
  `
  <div>
    공구핫딜 하림 치킨너겟 (480g+300g) 9,980원
    공구핫딜 고래사 꼬치어묵(10+2입) 6,940원
    공구핫딜 고래사 5가지 야채듬뿍 사각어묵(450g) 4,330원
    공구핫딜 한성 와일드 크래미 (180g×3) 6,980원
    공구핫딜 롯데 비엔나 소시지(600g) 7,980원
    CJ 가쓰오 우동(4인) 8,980원
    롯데 식사이론 만두 3종(각 700g) ※대파고기/단호박닭가슴살/메밀부추 각8,480원
    동원 살코기 마일드 참치기획 (90g×3입+90g×3입) L.POINT 4,500원 할인 9,980원
    하인즈 파스타소스 5종(각 350g) L.POINT 40% ※베이컨 토마토 外 4종 ※점별 운영상품 상이 각3,588~3,888원
    동원 소와나무 순수우유 기획 (900ml×2입) ※기간 : 10/1(화)~10/6(일) 3,880원
    공구핫딜 칠성사이다(190ml×30입)/공구핫딜 코카콜라(215ml×30입) 14,490/18,360원 칠성사이다 개당 483원! 코카콜라 개당 612원!
    공구핫딜 팜앤홈 제주감귤 (1.2L×4입) 5,880원
    환타 오렌지,파인애플(각 1L)/파워에이드, 토레타, 토레타제로(각 900ml) 최대1,400원 할인 각1,000원
    롯데마트 단독 매일 바이오 그릭×그래놀라 기획(400g×2입) 8,980원
    공구핫딜 롯데 초코파이(40g×27입) ※점별 운영상품 상이 8,880원
    켈로그 프링글스 미니캔 번들(636g/오리지날 6입, 양파맛 6입) ※점별 운영상품 상이 11,980원
    켈로그 브롤스타즈 기획팩(1kg) ※점별 운영상품 상이 11,980원
    롯데 부여알밤 과자/디저트빵 8종 (상품별 용량 상이) ※롯데 빈츠 부여알밤 外 ※점별 운영상품 상이 각1,580~7,980원
    슈퍼너츠 피넛버터 2종 (각 460g, 스무스/크런치) 각2,500원 할인 각 11,900원
    공구핫딜 뉴트리원 이뮨플러스(193.5g) 7+2 기획팩 13,900원
    엘지생활건강 3만원 이상 구매시 5,000원 롯데상품권 증정 ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※결제시 계산대에서 수령 ※구분자 "ⓩ"을 확인하세요 ※각 브랜드별 구분자를 확인하세요 ※일부품목 상품권 증정 행사 제외
    
    2개 이상 50%
    피지 액체세제 9종(상품별 상이)
    ※디나자임/탈취실내건조/어두운의류용
    ※기간 : 10/1(화)~10/6(일)
    2개 이상 구매시 1개당 각9,450~9,900원
    ※1개 구매시 각18,900~19,800원 ※교차구매 가능

    2개 이상 50%
    아우라 퍼퓸 리필 (각 1.7L, 로즈/베르가못)
    2개 이상 구매시 1개당 각4,950원
    ※1개 구매시 각9,900원 ※교차구매 가능

    2개 이상 50%
    페리오 토탈7 인텐스 치약 2종
    (리프레쉬쿨링/프리징쿨링)
    ※점별 운영상품 상이
    2개 이상 구매시 1개당 각6,990원
    ※1개 구매시 각13,980원 ※교차구매 가능

    2개 이상 50%
    페리오 이지클리닉 칫솔 2종 (초극세모/탄력모) ※점별 운영상품 상이
    2개 이상 구매시 1개당 각4,450원
    ※1개 구매시 각8,900원 ※교차구매 가능

    2개 이상 50%
    엘지생활건강
    클렌징 폼 전품목(상품별 상이) 
    ※점별 운영상품 상이
    ※일부 재고소진 품목 제외
    ※일부품목 상품권 증정 행사 제외
    2개 이상 구매시 1개당 각3,950~6,900원
    ※1개 구매시 각7,900~13,800원 ※교차구매 가능

    2개 이상 50%
    온더바디 바디워시 전품목(상품별 상이) ※
    점별 운영상품 상이
    ※일부품목 상품권 증정 행사 제외
    ※기간 : 10/1(화)~10/6(일) 
    2개 이상 구매시 1개당 각5,450~8,250원
    ※1개 구매시 각10,900~16,500원 ※교차구매 가능

    2개 이상 50%
    엘라스틴 전품목(상품별 상이)
    ※점별 운영상품 상이 ※기간 : 10/1(화)~10/6(일)
    2개 이상 구매시 1개당 각5,450~11,000원
    ※1개 구매시 각10,900~22,000원 ※교차구매 가능

    2개 이상 50%
    리엔 보윤 샴푸/컨디셔너 3종(각 1,000ml) 
    ※점별 운영상품 상이
    각9,540원

    공구핫딜 순샘 레몬 (2L+2L) 7,900원
    공구핫딜 퍼실 파워젤/실내건조 기획(각 2.7L+2.2L) 각24,990원
    공구핫딜 고농축피죤 기획(파우더라일락, 2L+2L) 13,900원
    공구핫딜 케라시스 비듬케어 쿨링샴푸(각 1,000ml, 딥클린/가려움완화) 각5,900원
    공구핫딜 샤워메이트 플라워퍼퓸 바디워시 옐로우(프리지아&쟈스민향 1,200ml) 4,900원
    물가안정 인기 생필품
    피지오겔/바이오더마/세타필 더마 스킨케어 5종(상품별 상이) 롯데/KB국민/신한/NH농협/삼성카드 10% ※페이지 하단 카드할인 세부내용 참고 ※점별 운영상품 상이 각17,010~24,219원
    도브 바디로션 3종(각 400ml) 2개 이상50% ※점별 운영상품 상이 ※너리시드/셔플바운스/라이트 2개 이상 구매시 1개당 각7,950원 ※1개 구매시 각15,900원 ※교차구매 가능
    존슨즈 베이비로션 3종 (상품별 상이) 2개 이상50% ※점별 운영상품 상이 ※핑크 500ml+200ml, 화이트/베드타임 각 500ml 2개 이상 구매시 1개당 각8,950~10,950원 ※1개 구매시 각17,900~21,900원 ※교차구매 가능

    자연퐁 전품목(상품별 상이) 롯데/KB국민/신한/NH농협/삼성카드 2개 이상 60%
    ※페이지 하단 카드할인 세부내용 참고
    ※일부품목 제외 ※기간 : 10/1(화)~10/6(일)
    해당카드로 2개 이상 구매시 1개당 각3,160~7,920원
    ※1개 구매시 각7,900~19,800원 ※교차구매 가능

    깨끗한나라 페퍼민트 물티슈(캡형 70매×6입)  2개 이상50%
    ※기간 : 10/1(화)~10/6(일)
    2개 이상 구매시 1개당 각6,450원
    ※1개 구매시 12,900원

    모나리자 행사상품 2만 5천원 이상 구매시
    5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령 ※구분자 "㉤"을 확인하세요

    모나리자 코튼화이트 (30m×30롤) 2개 이상 50%
    2개 이상 구매시 1개당 ※1개 구매시 30,800원
    ※1개 구매시 30,800원

    하기스 행사상품 10만원 이상 구매시
    1만원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령 ※구분자 "㉡"을 확인하세요

    하기스 맥스드라이/매직컴포트/네이처메이드 29종(상품별 상이) 2개 이상 50%
    ※점별 운영상품 상이 ※기간 : 10/1(화)~10/9(수)
    2개 이상 구매시 1개당
    각15,300~26,400원
    ※1개 구매시 각30,600~52,800원 ※교차구매 가능

    크리넥스 마이비데 물티슈 전품목 (상품별 상이) L.POINT 할인 ※점별 운영상품 상이 ※L.POINT회원에 한함 각1,813~10,430원
    순수한면 제로 울트라날개 기획 (중형 16매×3입+대형 14매) 4,000원 할인 ※기간 : 10/1(화)~10/6(일) 9,900원

    ※정상가란? 2024년 9월 10일(화) ~ 9월 30일(월) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    가을특가 리빙 플렉스

    40%
    에브리데이쿡 프라이팬 4종/
    뉴이모션 프라이팬 5종(상품별 상이)
    18,480~39,480원

    최대 20%
    3M 다목적 수세미/
    제로스크레치 수세미(각 10입)
    ※3M 상품권 증정행사 미해당 품목
    6,390/8,790원

    공구핫딜 실속형 종이컵(600P)/
    테이크아웃 종이컵(300P)
    9,900/15,900원

    1,790원 할인
    더좋은 부탄가스(4입)
    5,000원

    20%
    오늘좋은 세라믹
    팬냄비(상품별 상이)
    20,720~39,120원

    2입 기획
    락앤락 김치통 2P기획 4종
    (2.6L/3.6L/4.5L/8L)
    각9,900~21,000원

    30%
    오늘좋은 극세사 누빔패드
    (싱글 110×200cm/퀸 150×220cm)
    ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이
    24,430~27,930원

    2개 이상 50%
    오늘좋은 세탁이 편리한 베개
    (40×60cm/50×70cm)
    2개 이상 구매시 1개당 각10,950/14,950원
    ※1개 구매시 각 21,900/29,900원 ※교차구매 가능

    20%
    오늘좋은
    메모리폼 토퍼
    (S/Q) ※베이직/더 두꺼운
    79,200~151,920원

    30%
    룸바이홈 럭셔리 세면타월
    (각 4P) ※네이비/화이트/그레이
    각18,130원

    10%
    디클린 정수필터 4종(상품별 상이)
    7,560~18,610원

    2개 이상 50%
    룸바이홈 호텔 발매트
    (각 45×65cm)
    ※챠콜/베이지/그레이 ※입점점에 한함
    ※해당상품에 한함 ※점별 재고 상이
    ※1개 구매시 각 13,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각6,950원
    ※1개 구매시 각 13,900원 ※교차구매 가능

    3M 해당상품
    2만5천원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※'도와드리겠습니다'센터 증정
    ※구분자 "M"를 확인하세요

    3M 올터치 막대걸레
    (표준형/대형) ※입점점에 한함
    ※해당상품에 한함 ※점별 재고 상이
    26,290/29,590원

    최대 50%
    아이리스 시스템 수납함 2종/
    우드탑 서랍장 2종(상품별 상이)
    7,450~76,300원

    최대 30%
    오스람/레드밴스 전구조명기구
    (전구/형광등/등기구/취침등)
    ※점별 재고상이 ※한정수량으로 조기소진될 수 있습니다
    3,700~31,820원

    엑스가드 레저소품(상품별 상이)
    ※귀걸이 멀티프, 트레이닝 장갑 外
    5,900~17,900원

    오늘좋은 히트 동내의(상품별 상이)
    7,900~15,900원

    확장지퍼로 수납력 확대!
    더블 4휠 바퀴 장착으로
    이동시 저소음!
    트래커 여행가방(기내용 16/20인치, 화물용 24/28인치)
    59,000~99,000원

    최대25,000원 할인
    허밋 원터치 텐트
    (2~3인용/3~4인용) ※입점점에 한함
    39,900/49,900원

    토이저러스 닌텐도 스위치 10종 L.POINT 1만원 할인

    L.POINT 각 10,000원
    스위치 슈퍼마리오브라더스
    원더 外 9종(전체 이용가)
    ※입점점에 한함 ※점별 재고 상이
    각54,800원

    위시캣 방영 신상품 출시 20종
    (상품별 상이) ※입점점에 한함 ※점별 재고 상이
    12,900~95,900원

    위스카스 콜리올리 반려동물 식/용품 해당 상품20%

    20%
    마즈 위스카스 사료(상품별 상이)
    960~20,720원

    20%
    콜리올리
    벤토나이트
    모래 2종(라벤더향/무향)
    각7,120원

    오늘 뭐 먹지? 간편하게 밀키트로, 오늘의 저녁 메뉴 데일리 밀 솔루션
    롯데/신한/KB국민/NH농협/삼성카드 구매시 50% 할인! 5천원 롯데상품권 증정! 기간 10월 1일 화요일부터 10월 16일 수요일까지

    혜택1 냉동밀키트&냉동밥면 롯데/신한/KB국민/NH농협/삼성카드 2개 이상 구매시 50% 할인
    ※교차구매 가능 ※PB/기획상품 제외
    ※데일리 밀 솔루션 존 상품에 한함
    ※페이지 하단 카드할인 세부내용 참고

    혜택2 Daily Meal Solution 전품목(밀키트, 밥/면, 반찬, 안주류) 롯데/신한/KB국민/NH농협/삼성카드 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※'도와드리겠습니다'센터 증정
    ※구분자 "★"를 확인하세요
    ※데일리 밀 솔루션 존 상품에 한함
    ※페이지 하단 카드할인 세부내용 참고

    L.POINT 2,000원
    요리하다 꽃게탕(660g) 11,900원

    1,000원 할인
    요리하다×두끼 즉석떡볶이/마라떡볶이(560g/520g) 각4,990원

    500원 할인
    오늘좋은 오리지널&카라멜 팝콘(280g) 2,990원

    L.POINT 1,000원
    오늘좋은×고래사 종합모둠어묵탕(700g) 6,990원

    ※정상가란? 2024년 9월 10일(화) ~ 9월 30일(월) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
];

const zettaSeoulData = [
  `
  <h1>LOTTE Mart ZETTAPLEX</h1>
  
  <div>
    ·전단적용기간 : 2024. 10. 1(화) ~ 2024. 10. 16(수) < 신선식품 적용기간 : 2024. 10. 1(화) ~ 10. 9(수) >
    농림축산식품부, 한돈자조금관리위원회와 함께합니다.
    통큰혜택, 통큰할인, 통큰가격, 통큰선물 황금연휴 통큰딜 기간 10월 1일 화요일부터 10월 6일 일요일까지
    국내산 한돈 삼겹살/목심 (각 100g/냉장/국내산) ※제주점 제외 ※MAP(산소포장팩) 제외 ※조기 품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고 각1,890원
    킹크랩 (100g/러시아산) 롯데/KB국민/신한/NH농협/삼성카드 50% ※제주점 제외 ※산지 시황에 따라 조기 품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고 4,995원 ※기간 : 10/1(화) ~ 점별 재고 소진시까지
    New 한통가아득 치킨(1팩/국내산 계육) 롯데/KB국민/신한/NH농협/삼성카드 ※페이지 하단 카드할인 세부내용 참고 7,794원
    행복생생란(대란/30구/국산) 2판 이상 각1,000원 할인 2판 이상 구매시 1판당 각5,990원 ※1판 구매시 6,990원
    상생 대파(봉/국산) 롯데/신한/NH농협카드 400원 할인 ※1인 2봉 한정 ※ 일 한정수량 판매 상품으로 점포별 조기 마감될 수 있습니다
    봉지라면 13종(각 4개입) 1+1 ※오뚜기 마열라면 外 12종 ※동일 브랜드 內 동일 가격 간 교차구매 가능 각3,320~8,800원
    물/군/얇피/수제만두 25종 롯데/KB국민/신한/NH농협/삼성카드 2개 이상 50% (상품별 용량 상이) 해당카드로 2개 이상 구매시 1개당 각3,490~5,440원 ※1개 구매시 각 6,980~10,880원 ※기간 : 10/3(목) ~ 10/6(일) ※교차구매 가능 ※페이지 하단 카드할인 세부내용 참고
    반값 득템 초밥 30입 (1팩/원산지 별도표기) 14,995원
    좋은느낌 브랜드 세일(상품별 상이) 2개 이상 50% 좋은느낌 행사상품 3만원 이상 구매시 5천원 롯데상품권 증정 ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※구분자 '㉨'를 확인하세요 ※결제시 계산대에서 수령 ※점별 운영상품 상이 ※교차구매 가능(팬티라이너 제외) 2개 이상 구매시 1개당 각2,500~9,450원 ※1개 구매시 각 5,000~18,900원
    도루코 퓨어우드 IH 프라이팬 5종(20~30cm) ※L.POINT 회원에 한함 ※교차구매 가능 2개 이상 50% 2개 이상 구매시 1개당 각16,450~21,950원 ※1개 구매시 각 32,900~43,900원
    16입 두유 전품목(각 190ml×16입) 1+1 ※동일 시리즈 상품간 교차구매 가능 14,900~20,000원
    고객 여러분의 밥상물가를 지킬 10대 아이템 미션! 물가를 잡아라 롯데마트&롯데슈퍼가 물가안정에 앞장섭니다. 기간:10/1(화) ~10/16(수) (신선식품 적용은 10/1(화)~10/9(수), 9일간)
    민속 친한우 전품목 1+/1++ 등급(각 100g/냉장/국내산 한우고기) L.POINT 최대 50%
    프리미엄 파타고니아 연어 필렛(500g/냉장/칠레산) 19,900원
    2024 롯데마트가 매주 여러분의 경제적 소비생활을 지원합니다 이번주 핫 프라이스 육즙 가득 소고기와 향긋한 대파의 만남! 든든한 한끼 식사 뚝딱 완성 '특가' 산더미 대파 소불고기 (800g/냉장/원산지 별도표기) 롯데/KB국민/신한/NH농협/삼성카드 50%12,900원 ※기간 : 10/1(화) ~ 10/9(수) ※조기 품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고
    청도 홍시(4~6입/팩/국산) 2팩 이상 각 2,000원 할인 ※1팩 구매시 7,990원 2팩 이상 구매시 1팩당 각5,990원 ※1팩 구매시 7,990원
    공구핫딜 구운 아몬드/마카다미아 (800g/350g, 원산지 별도표기) 각9,990원
    삼진어묵 시원한 모듬어묵/쫄깃한 야채사각(456g/200g) 1+1 ※동일 상품에 한함 7,480/3,280원
    풀무원 서울라면/서울짜장 (각 4개입) 1+1 ※교차구매 가능 각5,450원
    떠먹는 요구르트 30종(각 80g×4입/각 85g×4입) 1+1 ※빙그레/풀무원/남양/매일/일동 外 ※동일 브랜드 內 동일 가격 간 교차구매 가능 각5,450원
    녹차원 꿀 유자차/레몬생강차 外 3종 (각 1kg) ※해당 상품에 한함 ※교차구매 가능 
    잘풀리는집 굿딜 3겹 화장지(30m×30롤) 롯데/KB국민/신한/NH농협/삼성카드 50% ※페이지 하단 카드할인 세부내용 참고 2개 이상 구매시 1개당 각13,900원 ※1개 구매시 27,800원
    테크 실내건조 베이킹+구연산 (각 3.4L, 일반/드럼) 1+1 ※교차구매 가능 각19,800원

    ※정상가란? 2024년 9월 10일(화) ~ 9월 30일(월) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함   
  </div>
  `,
  `
  <div>
    호주청정우 기획팩 8종 (품목별 상이/냉장/호주산) 2팩 이상 각 2,000원 할인 ※1팩 구매시 각 11,900원 ※교차구매 가능 2팩 이상 구매시 1팩당 각9,900원 ※1팩 구매시 각 11,900원 ※교차구매 가능
    호주청정우 척아이롤 100g/냉장/호주산 L.POINT 50% ※기간 : 10/1(화)~10/6(일) ※10/7(월)~10/9(수) : L.POINT 40% 할인 ※국거리/다짐육 제외
    참프레/정다운 훈제오리 슬라이스 (각 400g/냉장/원산지 별도표기) 1+1 각 14,990원
    수산물 구매금액의 1%가 지속가능한 어업활동에 기부됩니다
    완도 전복(특) (마리/냉장/국산) L.POINT 25% 3,450원
    제주 갈치(대) (3마리/해동/국산) 9,900원
    번들새우살 3종 (각 250g×2입/냉동/원산지 별도표기) L.POINT 40% 11,880원
    광어 연어 모둠회 (400g 내외/냉장/원산지 별도표기) L.POINT 30% 29,820원 ※입점점에 한함
    제주 황토밭 하우스 감귤 (1.5kg/박스/국산) 롯데/KB국민/신한/NH농협/삼성카드 1,000원 할인 11,990원 ※기간 : 10/1(화)~10/6(일) ※페이지 하단 카드할인 세부내용 참고
    제스프리 골드키위 (개/뉴질랜드산) 8개 구매시 9,920원 ※1개 구매시 1,490원
    사과대추(500g/팩/국산) L.POINT 2,000원 할인 7,990원 ※기간 : 10/1(화)~10/6(일) ※점별 운영상품 상이 ※조기 품절될 수 있습니다
    24년 김장준비! 절임배추&햇 태양초 사전예약 ※세부내용은 매장 고지물 참고
    해남 절임배추 (20kg/국산) L.POINT 5,000원 할인 롯데/신한/NH농협카드 5,000원 추가할인 29,900원 ※ 페이지 하단 카드할인 세부내용 참고 ※예약기간 : 10/1(화)~10/6(일) ※수령일 : 11/8(금)~12/21(토) ※한정물량 1만 박스 선착순 한정 ※물량 소진시 엘포인트 할인가 34,900원 적용
    괴산 절임배추 20kg/국산 35,900원 평창 절임배추 20kg/국산 59,900원 영월 절임배추 20kg/국산 54,900원 절임 알타리 5kg/국산 39,900원
    햇 건고추 사전예약! 햇 태양초 건고추/제분 (1.8kg/1.48kg, 국산) 각49,900원 ※예약기간 : 10/1(화)~10/16(수) ※매장에 다양한 상품이 안내되어 있습니다
    경기도식, 전라도식/강원도식 김치양념(각 3.5kg/원산지 별도표기) 39,900/45,900원
    GAP 완전미 고시히카리(10kg/국산) ※기간 : 10/1(화)~10/6(일) 34,900원
    양반 들기름향이 그윽한 식탁김/CJ 비비고 직화 참기름김(4.5g×20봉/4g×20봉, 원산지 별도표기) 각7,980원
    5무 훈제오리(1팩/원산지 별도표기) 10,990원 
    샌드위치 10종(1팩/원산지 별도표기) ※듬뿍 햄치즈 샌드위치 外 9종 각5,990원
    해물 양장피(1팩/원산지 별도표기) L.POINT 20% 15,992원
    반찬 골라담기(4팩/원산지 별도표기) 3+1 10,000원 ※미찬, 비움, 도시곳간, 무명찬반, 웰빙앤찬, 초이스쿡 입점점에 한함 ※팩당 4,000원 이하 상품 한정
    부드러운 호밀빵(8입/1팩/원산지 별도표기) 1,500원 할인 5,900원※ 베이커리팩토리/쁘띠르뽀미에 입점점에 한함
    하나사면 하나 더 1+1
    동원 통그릴 부어스트(500g) 1+1 8,980원
    유부초밥 4종(상품별 상이) 1+1 5,280~5,680원 ※기간 : 10/3(목) ~ 10/6(일) ※동일 상품에 한함
    오뚜기 스파이시마요 포테이토피자(370g) 1+1 9,980원 ※기간 : 10/1(화) ~ 10/6(일)
    마니커 소금구이 통날개구이(400g) 1+1 11,980원
    오뚜기 파스타소스 12종 (상품별 규격 상이) 1+1 5,980~6,980원 ※기간 : 10/1(화) ~ 10/6(일) ※동일 가격 상품간 교차구매 가능
    하림 더미식 상온 국물요리 6종 (부대찌개 外 5종, 각 350g) 1+1 각5,980원 ※교차구매 가능
    사조 자연산 골뱅이(400g) 1+1 12,480원 ※기간 : 10/1(화) ~ 10/6(일)
    환절기 기능성 유아음료 16종 (상품별 규격 상이) 1+1 1,500~4,900원 ※동일 브랜드/동일 가격 교차구매 가능
    덴마크 인포켓 치즈 (200g/봉) 1+1 14,000원 ※기간 : 10/1(화)~10/6(일)
    서울 유기농 우유(700ml) 1+1 4,980원 ※기간 : 10/1(화)~10/6(일)
    탄산음료 대형 PET 전품목 (상품별 규격 상이) 최대 50% 1+1 각1,190~3,680원 ※상품별 행사 상이 ※기간 : 10/1(화)~10/6(일) ※동일 시리즈 간 교차구매 가능
    한진 야채 크래커/우유 크래커(각 240g) 1+1 각7,980원 ※기간 : 10/1(화) ~ 10/6(일) ※교차구매 가능
    삼립 미니꿀호떡 2종(각 192g, 오리지널/옥수수) 1+1 각3,480원 ※교차구매 가능
    자임 하루하나 유기농 레몬즙(20g×14입) 1+1 15,900원
    2개 이상 구매시 더 싸게
    CJ 비비고 통새우만두/쌀떡볶이(상품별 규격 상이) 2개 이상 각 1,000원 할인 ※1개 구매시 각 6,980원 ※교차구매 가능 2개 이상 구매시 1개당 각5,980원 ※1개 구매시 각 6,980원 ※교차구매 가능
    풀무원 노엣지피자/핫도그 6종(상품별 상이) 2개 이상 각 3,980원 할인 ※기간 : 10/1(화) ~ 10/6(일)※1개 구매시 각 8,980원 ※교차구매 가능 2개 이상 구매시 1개당 각 5,000원 ※1개 구매시 각 8,980원 ※교차구매 가능
    냉장 밀키트 6종(상품별상이) 2개 이상 40% ※기간 : 10/3(목) ~ 10/6(일) 2개 이상 구매시 1개당 각5,988~8,940원 ※1개 구매시 각 9,980~14,900원 ※교차구매 가능
    농심 봉지라면 전품목 42종(상품별 규격 상이) 2개 이상 10% ※올리브 짜파게티 5개입 外 41종 2개 이상 구매시 1개당 각1,422~14,040원 ※1개 구매시 각 1,580~15,600원 ※교차구매 가능
    샘표 고추장/된장/쌈장 전품목 11종(상품별 용량 상이) 2개 이상 50% 2개 이상 구매시 1개당 각1,245~16,490원 ※1개 구매시 각 2,490~32,980원 ※교차구매 가능
    빙그레 벨치즈 8종 (상품별 용량 상이) 2개 이상 50% ※빙그레 래핑카우 플레인 外 7종 ※기간 : 10/1(화) ~ 10/6(일) 2개 이상 구매시 1개당 각2,990~5,290원 ※1개 구매시 각 5,980~10,580원 ※교차구매 가능
    파스퇴르 위편한/속편한 요구르트(각 150ml×4입) 2개 이상 50% ※기간 : 10/1(화) ~ 10/6(일) 2개 이상 구매시 1개당 각3,340원 ※1개 구매시 각 6,680원 ※교차구매 가능
    켈로그 그래놀라 5종(상품별 용량 상이) 2개 이상 10% ※켈로그 크랜베리 그래놀라 外 2개 이상 구매시 1개당 각5,922원 ※1개 구매시 각 6,580원 ※교차구매 가능
    제주 삼다수 그린(2L×6입/500ml×20입) 2개 이상 30% ※기간 : 10/1(화)~10/6(일) ※제주점 제외 2개 이상 구매시 1개당 각4,536/6,720원 ※1개 구매시 각 6,480/9,600원 ※교차구매 가능

    ※정상가란? 2024년 9월 10일(화) ~ 9월 30일(월) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
    </div>
  `,
  `
  <div>
    공구핫딜 하림 치킨너겟 (480g+300g) 9,980원
    공구핫딜 고래사 꼬치어묵(10+2입) 6,940원
    공구핫딜 고래사 5가지 야채듬뿍 사각어묵(450g) 4,330원
    공구핫딜 한성 와일드 크래미 (180g×3) 6,980원
    공구핫딜 롯데 비엔나 소시지(600g) 7,980원
    CJ 가쓰오 우동(4인) 8,980원
    롯데 식사이론 만두 3종(각 700g) ※대파고기/단호박닭가슴살/메밀부추 각8,480원
    동원 살코기 마일드 참치기획 (90g×3입+90g×3입) L.POINT 4,500원 할인 9,980원
    하인즈 파스타소스 5종(각 350g) L.POINT 40% ※베이컨 토마토 外 4종 ※점별 운영상품 상이 각3,588~3,888원
    동원 소와나무 순수우유 기획 (900ml×2입) ※기간 : 10/1(화)~10/6(일) 3,880원
    공구핫딜 칠성사이다(190ml×30입)/공구핫딜 코카콜라(215ml×30입) 14,490/18,360원 칠성사이다 개당 483원! 코카콜라 개당 612원!
    공구핫딜 팜앤홈 제주감귤 (1.2L×4입) 5,880원
    환타 오렌지,파인애플(각 1L)/파워에이드, 토레타, 토레타제로(각 900ml) 최대1,400원 할인 각1,000원
    롯데마트 단독 매일 바이오 그릭×그래놀라 기획(400g×2입) 8,980원
    공구핫딜 롯데 초코파이(40g×27입) ※점별 운영상품 상이 8,880원
    켈로그 프링글스 미니캔 번들(636g/오리지날 6입, 양파맛 6입) ※점별 운영상품 상이 11,980원
    켈로그 브롤스타즈 기획팩(1kg) ※점별 운영상품 상이 11,980원
    롯데 부여알밤 과자/디저트빵 8종 (상품별 용량 상이) ※롯데 빈츠 부여알밤 外 ※점별 운영상품 상이 각1,580~7,980원
    슈퍼너츠 피넛버터 2종 (각 460g, 스무스/크런치) 각2,500원 할인 각 11,900원
    공구핫딜 뉴트리원 이뮨플러스(193.5g) 7+2 기획팩 13,900원
    엘지생활건강 3만원 이상 구매시 5,000원 롯데상품권 증정 ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매) ※결제시 계산대에서 수령 ※구분자 "ⓩ"을 확인하세요 ※각 브랜드별 구분자를 확인하세요 ※일부품목 상품권 증정 행사 제외
    
    2개 이상 50%
    피지 액체세제 9종(상품별 상이)
    ※디나자임/탈취실내건조/어두운의류용
    ※기간 : 10/1(화)~10/6(일)
    2개 이상 구매시 1개당 각9,450~9,900원
    ※1개 구매시 각18,900~19,800원 ※교차구매 가능

    2개 이상 50%
    아우라 퍼퓸 리필 (각 1.7L, 로즈/베르가못)
    2개 이상 구매시 1개당 각4,950원
    ※1개 구매시 각9,900원 ※교차구매 가능

    2개 이상 50%
    페리오 토탈7 인텐스 치약 2종
    (리프레쉬쿨링/프리징쿨링)
    ※점별 운영상품 상이
    2개 이상 구매시 1개당 각6,990원
    ※1개 구매시 각13,980원 ※교차구매 가능

    2개 이상 50%
    페리오 이지클리닉 칫솔 2종 (초극세모/탄력모) ※점별 운영상품 상이
    2개 이상 구매시 1개당 각4,450원
    ※1개 구매시 각8,900원 ※교차구매 가능

    2개 이상 50%
    엘지생활건강
    클렌징 폼 전품목(상품별 상이) 
    ※점별 운영상품 상이
    ※일부 재고소진 품목 제외
    ※일부품목 상품권 증정 행사 제외
    2개 이상 구매시 1개당 각3,950~6,900원
    ※1개 구매시 각7,900~13,800원 ※교차구매 가능

    2개 이상 50%
    온더바디 바디워시 전품목(상품별 상이) ※
    점별 운영상품 상이
    ※일부품목 상품권 증정 행사 제외
    ※기간 : 10/1(화)~10/6(일) 
    2개 이상 구매시 1개당 각5,450~8,250원
    ※1개 구매시 각10,900~16,500원 ※교차구매 가능

    2개 이상 50%
    엘라스틴 전품목(상품별 상이)
    ※점별 운영상품 상이 ※기간 : 10/1(화)~10/6(일)
    2개 이상 구매시 1개당 각5,450~11,000원
    ※1개 구매시 각10,900~22,000원 ※교차구매 가능

    2개 이상 50%
    리엔 보윤 샴푸/컨디셔너 3종(각 1,000ml) 
    ※점별 운영상품 상이
    각9,540원

    공구핫딜 순샘 레몬 (2L+2L) 7,900원
    공구핫딜 퍼실 파워젤/실내건조 기획(각 2.7L+2.2L) 각24,990원
    공구핫딜 고농축피죤 기획(파우더라일락, 2L+2L) 13,900원
    공구핫딜 케라시스 비듬케어 쿨링샴푸(각 1,000ml, 딥클린/가려움완화) 각5,900원
    공구핫딜 샤워메이트 플라워퍼퓸 바디워시 옐로우(프리지아&쟈스민향 1,200ml) 4,900원
    물가안정 인기 생필품
    피지오겔/바이오더마/세타필 더마 스킨케어 5종(상품별 상이) 롯데/KB국민/신한/NH농협/삼성카드 10% ※페이지 하단 카드할인 세부내용 참고 ※점별 운영상품 상이 각17,010~24,219원
    도브 바디로션 3종(각 400ml) 2개 이상50% ※점별 운영상품 상이 ※너리시드/셔플바운스/라이트 2개 이상 구매시 1개당 각7,950원 ※1개 구매시 각15,900원 ※교차구매 가능
    존슨즈 베이비로션 3종 (상품별 상이) 2개 이상50% ※점별 운영상품 상이 ※핑크 500ml+200ml, 화이트/베드타임 각 500ml 2개 이상 구매시 1개당 각8,950~10,950원 ※1개 구매시 각17,900~21,900원 ※교차구매 가능

    자연퐁 전품목(상품별 상이) 롯데/KB국민/신한/NH농협/삼성카드 2개 이상 60%
    ※페이지 하단 카드할인 세부내용 참고
    ※일부품목 제외 ※기간 : 10/1(화)~10/6(일)
    해당카드로 2개 이상 구매시 1개당 각3,160~7,920원
    ※1개 구매시 각7,900~19,800원 ※교차구매 가능

    깨끗한나라 페퍼민트 물티슈(캡형 70매×6입)  2개 이상50%
    ※기간 : 10/1(화)~10/6(일)
    2개 이상 구매시 1개당 각6,450원
    ※1개 구매시 12,900원

    모나리자 행사상품 2만 5천원 이상 구매시
    5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령 ※구분자 "㉤"을 확인하세요

    모나리자 코튼화이트 (30m×30롤) 2개 이상 50%
    2개 이상 구매시 1개당 ※1개 구매시 30,800원
    ※1개 구매시 30,800원

    하기스 행사상품 10만원 이상 구매시
    1만원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령 ※구분자 "㉡"을 확인하세요

    하기스 맥스드라이/매직컴포트/네이처메이드 29종(상품별 상이) 2개 이상 50%
    ※점별 운영상품 상이 ※기간 : 10/1(화)~10/9(수)
    2개 이상 구매시 1개당
    각15,300~26,400원
    ※1개 구매시 각30,600~52,800원 ※교차구매 가능

    크리넥스 마이비데 물티슈 전품목 (상품별 상이) L.POINT 할인 ※점별 운영상품 상이 ※L.POINT회원에 한함 각1,813~10,430원
    순수한면 제로 울트라날개 기획 (중형 16매×3입+대형 14매) 4,000원 할인 ※기간 : 10/1(화)~10/6(일) 9,900원

    ※정상가란? 2024년 9월 10일(화) ~ 9월 30일(월) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    가을특가 리빙 플렉스

    40%
    에브리데이쿡 프라이팬 4종/
    뉴이모션 프라이팬 5종(상품별 상이)
    18,480~39,480원

    최대 20%
    3M 다목적 수세미/
    제로스크레치 수세미(각 10입)
    ※3M 상품권 증정행사 미해당 품목
    6,390/8,790원

    공구핫딜 실속형 종이컵(600P)/
    테이크아웃 종이컵(300P)
    9,900/15,900원

    1,790원 할인
    더좋은 부탄가스(4입)
    5,000원

    20%
    오늘좋은 세라믹
    팬냄비(상품별 상이)
    20,720~39,120원

    2입 기획
    락앤락 김치통 2P기획 4종
    (2.6L/3.6L/4.5L/8L)
    각9,900~21,000원

    30%
    오늘좋은 극세사 누빔패드
    (싱글 110×200cm/퀸 150×220cm)
    ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이
    24,430~27,930원

    2개 이상 50%
    오늘좋은 세탁이 편리한 베개
    (40×60cm/50×70cm)
    2개 이상 구매시 1개당 각10,950/14,950원
    ※1개 구매시 각 21,900/29,900원 ※교차구매 가능

    20%
    오늘좋은
    메모리폼 토퍼
    (S/Q) ※베이직/더 두꺼운
    79,200~151,920원

    30%
    룸바이홈 럭셔리 세면타월
    (각 4P) ※네이비/화이트/그레이
    각18,130원

    10%
    디클린 정수필터 4종(상품별 상이)
    7,560~18,610원

    2개 이상 50%
    룸바이홈 호텔 발매트
    (각 45×65cm)
    ※챠콜/베이지/그레이 ※입점점에 한함
    ※해당상품에 한함 ※점별 재고 상이
    ※1개 구매시 각 13,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각6,950원
    ※1개 구매시 각 13,900원 ※교차구매 가능

    3M 해당상품
    2만5천원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※'도와드리겠습니다'센터 증정
    ※구분자 "M"를 확인하세요

    3M 올터치 막대걸레
    (표준형/대형) ※입점점에 한함
    ※해당상품에 한함 ※점별 재고 상이
    26,290/29,590원

    최대 50%
    아이리스 시스템 수납함 2종/
    우드탑 서랍장 2종(상품별 상이)
    7,450~76,300원

    최대 30%
    오스람/레드밴스 전구조명기구
    (전구/형광등/등기구/취침등)
    ※점별 재고상이 ※한정수량으로 조기소진될 수 있습니다
    3,700~31,820원

    엑스가드 레저소품(상품별 상이)
    ※귀걸이 멀티프, 트레이닝 장갑 外
    5,900~17,900원

    오늘좋은 히트 동내의(상품별 상이)
    7,900~15,900원

    확장지퍼로 수납력 확대!
    더블 4휠 바퀴 장착으로
    이동시 저소음!
    트래커 여행가방(기내용 16/20인치, 화물용 24/28인치)
    59,000~99,000원

    최대25,000원 할인
    허밋 원터치 텐트
    (2~3인용/3~4인용) ※입점점에 한함
    39,900/49,900원

    토이저러스 닌텐도 스위치 10종 L.POINT 1만원 할인

    L.POINT 각 10,000원
    스위치 슈퍼마리오브라더스
    원더 外 9종(전체 이용가)
    ※입점점에 한함 ※점별 재고 상이
    각54,800원

    위시캣 방영 신상품 출시 20종
    (상품별 상이) ※입점점에 한함 ※점별 재고 상이
    12,900~95,900원

    위스카스 콜리올리 반려동물 식/용품 해당 상품20%

    20%
    마즈 위스카스 사료(상품별 상이)
    960~20,720원

    20%
    콜리올리
    벤토나이트
    모래 2종(라벤더향/무향)
    각7,120원

    세계맥주 6캔 12,000원 골라담기
    세계맥주 골라담기
    (38종/상품별 용량 상이) 6캔 구매시 12,000원
    ※1캔 구매시 3,000원 ※4캔 구매시 9,000원

    L.POINT 1,700원
    코젤 보드게임 패키지
    (500ml×6)
    15,800원

    요리하다
    온가족 먹거리 추천!

    요리하다 허니
    바사삭치킨(375g)
    5,990원

    1,000원 할인
    요리하다×두끼 즉석떡볶이/
    마라떡볶이(560g/520g)
    각4,990원

    L.POINT 2,000원
    요리하다 꽃게탕(660g)
    11,900원

    요리하다 정통 가쓰오우동
    (484g)
    4,990원

    오늘 가장 좋은 선택 BEST MD'S PICK
    좋은 상품을 최적의 가격에, 롯데전문MD.롯데중앙연구소 PB개발 노하우로 만든 대표 브랜드

    500원 할인
    오늘좋은 오리지널
    &카라멜 팝콘(280g)
    2,990원

    오늘좋은 버터갈릭 새우칩/
    블랙패퍼쉬림프 새우칩(각 155g)
    각2,000원

    오늘좋은 먹태칩(50g)/
    옥수수 똘뱅이(195g)
    1,000/2,000원

    오늘좋은×고래사
    종합모둠어묵탕(700g)
    6,990원

    ※정상가란? 2024년 9월 10일(화) ~ 9월 30일(월) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함


  </div>
  `,
];

const fillZero = (width, str) => {
  return str.length >= width
    ? str
    : new Array(width - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
};

const getAllStoreData = (type) => {
  const dataArray = [];
  allStoreData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/${type === "ZETTA" ? "jamsil_" : ""}${fillZero(
        2,
        String(i + 1)
      )}.jpg`,
      title: `${type === "ZETTA" ? "제타플렉스" : "롯데마트"} 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getZettaData = () => {
  const dataArray = [];
  zettaData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/jamsil_${fillZero(2, String(i + 1))}.jpg`,
      title: `제타플렉스 잠실점 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getZettaSeoulData = () => {
  const dataArray = [];
  zettaSeoulData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/seoul_${fillZero(2, String(i + 1))}.jpg`,
      title: `제타플렉스 서울역점 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const imgPath = "images/202410_1";
const category = [];

const data = {
  L101: {
    title: "스마트전단지-전점101",
    category: category,
    type: "HYPER",
    isFooter: true,
    imgPath: imgPath,
    contents: getAllStoreData(""),
  },
  L102: {
    title: "스마트전단지-전점102",
    category: category,
    type: "HYPER",
    isFooter: true,
    imgPath: imgPath,
    contents: getAllStoreData(""),
  },
  L103: {
    title: "스마트전단지-제타플렉스 잠실점103",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaData(""),
  },
  L104: {
    title: "스마트전단지-제타플렉스 잠실점104",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaData(""),
  },
  L105: {
    title: "스마트전단지-제타플렉스 서울역점105",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaSeoulData(""),
  },
  L106: {
    title: "스마트전단지-제타플렉스 서울역점106",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaSeoulData(""),
  },
};

export default data;
