/* 309,310 롯데슈퍼(~10/9) */
const superData = [
  `
  <h1>LOTTE SUPER</h1>
  <div>
    전단적용기간 : 2024. 10. 1(화) ~ 2024. 10. 9(수) ※일부 상품 행사 기간 상이 ※일부 상품 행사 가격 연장 가능 ※점별 취급상품 상이
    통큰혜택, 통큰할인, 통큰가격, 통큰선물 황금연휴 통큰딜 기간 10월 1일 화요일부터 10월 6일 일요일까지

    롯데/KB국민/신한/삼성카드 25%
    국내산 무항생제 돼지고기 삼겹살
    (100g/냉장/국내산 돼지고기)
    2,993원
    ※해당 단일카드로 전액 결제시에 한함
    ※체크카드/앱카드 포함 ※KB국민BC/신한BC카드 제외

    무항생제 판계란(대란/30구/국산)
    6,990원

    영천시와 함께 농가를 응원합니다!
    L.POINT 2,000원 롯데카드/삼성카드 1,000원 추가할인
    비파괴 당도선별
    샤인머스캣(1.5kg/박스/국산)
    ※해당 단일카드로 전액 결제시에 한함
    ※체크카드/앱카드 포함
    12,990원 ※조기 품절될 수 있습니다

    햇 밤 고구마
    (1.5kg/박스/국산)
    5,990원

    GAP 완전미
    고시히카리(10kg/국산)
    34,900원

    공구핫딜
    하림 치킨너겟
    (480g+300g증정)
    10,490원

    롯데/KB국민/신한/삼성카드 50%
    동원 그릴리
    골든 베이컨(120g×2입)
    ※해당 단일카드로 전액 결제시에 한함
    ※체크카드/앱카드 포함 ※KB국민BC/신한 BC카드 제외
    3,495원

    1+1
    풀무원 미트/까르보나라 파스타
    (각 2인, 582g/460g) ※교차구매 가능
    각8,490원

    1+1
    CJ 고소함 가득
    참기름(500ml)
    14,950원

    1+1
    CJ 해찬들 새콤달콤
    초고추장(1.05kg)
    7,980원

    2+1
    카누 캡슐커피
    (각 5.7g×10입) ※교차구매 가능
    각6,980원

    각 1+1
    남양 드빈치 핑거 스트링치즈(203g)/
    동원 덴마크 인포켓 치즈(각 20g×10, 일반/라이트)
    12,990/각14,000원

    2개 이상 50%
    크리넥스
    수프림 소프트
    3겹 화장지
    (27m×30롤)
    2개이상 구매시 1개당 각19,700원 ※1개 구매시 39,400원

    롯데/KB국민/신한/삼성카드 800원
    엘라스틴 아미노프로틴
    클리닉 샴푸/린스(각 855ml)
    ※해당 단일카드로 전액 결제시에 한함
    ※체크카드/앱카드 포함
    ※KB국민BC/신한 BC카드 제외
    6,950원

    고객 여러분의 밥상물가를 지킬 10대 아이템 미션! 물가를 잡아라 롯데마트&롯데슈퍼가 물가안정에 앞장섭니다.

    팔도우수한우
    (민속친한우 1+등급)
    국거리/불고기
    (각 100g/냉장/국내산 한우고기)
    ※1등급 한우 병행 ※점포별 취급품 상이
    ※해당 단일카드로 전액 결제시에 한함
    ※체크카드/앱카드 포함 ※KB국민BC/신한 BC카드 제외
    롯데/KB국민/신한/삼성카드 30%

    L.POINT 10,000원
    프리미엄 파타고니아
    연어 필렛(500g/냉장/칠레산)
    19,900원

    2024 롯데마트와 롯데슈퍼가 매주 여러분의 경제적 소비생활을 지원합니다
    이번주 핫 프라이스 육즙가득 소고기와 향긋한 대파의 만남! 든든한 한끼 식사 뚝딱 완성 특가
    산더미 대파 소불고기(800g/냉장/원산지별도표기)
    L.POINT 45% 롯데/KB국민/신한/삼성카드 5%추가할인
    12,900원
      ※조기 품절될 수 있습니다 ※해당 단일카드로 전액 결제시에 한함
    ※체크카드/앱카드 포함 ※KB국민/신한 BC카드 제외

    ※정상가란? 2024년 9월 10일(화) ~ 9월 30일(월) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민/신한 BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
    </div>
  `,
  `
  <div>
    스위트 마운틴 바나나(1.1kg/송이/에콰도르산) 각2,990원
    L.POINT 2,000원 보조개 사과(1.5kg/봉/국산) ※ 조기 품절될 수 있습니다 7,990원
    8개 구매시 제스프리 골드키위(개/뉴질랜드산) 10,000원 ※1개 구매시 1,490원
    상생배(3kg/박스/국산) 9,990원
    온가족 인삼(500g/난밭/국산)/유명산지 금산 인삼(50g/국산) 17,900/4,990원
    2봉 이상 각 500원 할인 오이맛고추(150g/봉/국산) 2봉 이상 구매시 1봉당 각1,490원 ※1봉 구매시 1,990원
    제주 황토밭 하우스 감귤(1.5kg/박스/국산) 12,990원
    L.POINT 6,000원 다향 훈제오리 슬라이스(400g/냉장/원산지 별도표기) 11,900원
    2팩 이상 각 2,000원 할인 호주산 한끼구이팩 기획(250g~300g/냉장/호주청정우) ※ 부채살(구이/샤브샤브)/치마살/살치살/토시살/홍두깨육전 2팩 이상 구매시 팩당 각10,900원 ※1팩 구매시 각 12,900원
    L.POINT 2,000원 하림 매콤양념소스 닭볶음탕용(750g/냉장/국내산) 6,990원
    L.POINT 2,000원 완도 활전복(봉)(5마리/냉장/국산) 7,990원
    L.POINT 20% 완도 활전복(특)(마리/냉장/국산) 3,680원
    1+1 해표 더 고소한김 참기름/바삭돌김 (4g×16봉/4.5g×16봉, 원산지 별도표기) ※교차구매 가능 각11,980원
    L.POINT 40% 번들 새우살 3종(각 250g×2입/냉동/원산지 별도표기) 11,880원

    하나사면 하나 더 1+1
    1+1 마늘치킨 훈제슬라이스 (400g/냉장/원산지 별도표기) 10,900원
    1+1 롯데 켄터키 핫도그(350g) 8,990원
    1+1 오뚜기/삼양/하림/풀무원 봉지라면 9종 ※동일금액상품 교차구매 가능 각4,290~8,800원
    1+1 동원 리챔(200g) 5,090원
    1+1 동원 인기 파우치죽 6종(각 420g) ※ 양반쇠고기죽/양반단호박죽/양반밤단팥죽/양반버섯야채죽/양반전복죽/수라고려인삼전복삼계죽 ※동일금액상품 교차구매 가능 각4,980~5,980원
    1+1 떠먹는 요구르트 13종 ※ 매일 2종/빙그레 8종/풀무원 3종 ※동일 브랜드 교차구매 가능 각3,780~3,990원
    1+1 자임 스테비아 제주 레몬차/고흥 유자차(각 1kg) ※교차구매 가능 각10,900원
    1+1 롯데 칠성사이다/칠성사이다제로/펩시콜라/펩시제로라임(각 1.25L) 각3,580원
    1+1 롯데 팜앤홈 오렌지/망고(각 1.2L) 각4,480원
    1+1 수퍼보타닉 바디워시 (각 900ml) ※ 세이지&로즈마리/로즈&버베나 각8,900원
    1+1 아우라 퍼퓸 파라다이스리필(각 1.7L, 로즈/베르가못) 각9,900원
    공구핫딜 한성 와일드크래미(180g×3) 6,980원
    공구핫딜 고래사 5가지 야채듬뿍 사각어묵(450g) 4,330원
    롯데웰푸드 식사이론 만두 3종(각 350g×2) ※ 메밀만두/단호박치킨만두/대파고기만두 각8,490원
    롯데 부여알밤 과자(상품별 용량 상이) ※롯데 빈츠 부여알밤 外 각1,580~7,980원
    롯데 밀키스/밀키스 제로(각 500ml) 3개 구매시 3,000원 ※1개 구매시 각 1,200원
    
    24년 김장준비! 절임배추 사전예약
    L.POINT 5,000원 롯데/신한/삼성카드 5,000원 추가할인
    ※기간 : 10/1(화)~10/6(일), 6일간
    ※고객 수령일 : 11/8(금)~12/21(토)
    ※매장 안내데스크에서 기간 선택
    ※매장 안내데스크에서 지금 바로 예약하세요!

    ※한정물량 2만 박스 선착순 한정
    ※한정물량 종료 시 엘포인트 할인가 34,900원 적용
    ※해당 단일카드로 전액 결제시에 한함
    ※체크카드/앱카드 포함 ※신한 BC카드 제외
    해남 절임배추(20kg/국산) 29,900원
    괴산 절임배추(20kg/국산) 35,900원
    절임 알타리(5kg/국산)/김치 양념(각 3.5kg/국산) ※경기도식/전라도식 각39,900원

    맛있는 간편식 ※운영 점포에 한함
    THE큰치킨(마리/원산지 별도표기) 9,900원
    온가족 모둠초밥(30입/원산지 별도표기) 16,900원
    고추 크런치 치킨(팩/원산지 별도표기) 7,990원

    ※정상가란? 2024년 9월 10일(화) ~ 9월 30일(월) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
    
  </div>
  `,
];

/* 209 210 롯데슈퍼(~10/16) */
const superNewData = [
  `
  <h1>LOTTE SUPER</h1>
  <div>
    ·전단적용기간 : 2024. 10. 10(화) ~ 2024. 10. 16(수) ※일부 상품 행사 기간 상이 ※일부 상품 행사 가격 연장 가능 ※점별 취급상품 상이
    고객 여러분의 밥상물가를 지킬 아이템 미션 물가를 잡아라
    롯데마트&롯데슈퍼가 물가안정에 앞장섭니다.

    서해안 꽃게(100g/국산)
    1,272원

    GAP 친환경 흑타리 버섯
    (200g/팩/국산)
    990원

    풀무원 서울라면/서울짜장
    (각 4개입) ※교차구매 가능
    각5,450원

    떠먹는 요구르트 16종
    (상품별 용량 상이) ※매일/빙그레/풀무원
    ※동일 브랜드 內 동일 가격 간 교차구매 가능
    3,780~5,290원

    의성마늘 비엔나(160g×2입)
    4,490원

    녹차원 절임차 전품목 4종
    (각 1kg) ※교차구매 가능
    각9,900원

    잘풀리는집 굿딜 3겹 화장지(30m×30롤)
    ※해당 단일카드로 전액 결제시에 한함
    ※체크카드/앱카드 포함 ※KB국민BC/신한BC카드 제외
    13,900원

    블랙 페스타

    요리하다 냉장 양념육 전품목/
    제주 흑돼지 양념육
    (300g~1,000g 품목별 상이/냉장/원산지 별도표기)
    L.POINT 최대 9,000원 할인
    ※ 제주 흑돼지 간장/고추장 불고기, 양념 안창살/토시살 구이,
    1등급 양념 한우 불고기, 양념 소불고기

    호주산 와규 전품목(각 100g/냉장/호주산 청정와규)
    L.POINT 40%할인 + 롯데/KB국민/신한/삼성카드 결제시 10%할인
    ※상품별 할인율 상이 ※점포별 취급품 상이 ※해당 단일카드로 전액 결제시에 한함
    ※체크카드/앱카드 포함 ※KB국민BC/신한BC카드 제외

    공구핫딜 공동구매 가계절약
    공구핫딜은 롯데마트와 슈퍼가 공동 기획한 알뜰 한정 상품 입니다

    공구핫딜 해태 홈런볼
    15% 중량업(148g)
    3,280원

    공구핫딜 정식품 베지밀
    검은참깨 두유(190ml×16+8입)
    13,480원

    공구핫딜
    하림 치킨너겟
    증량기획(480g+300g)
    10,490원

    10+2 공구핫딜 고래사
    꼬치어묵 증량 기획
    6,940원

    공구핫딜 한성
    와일드크래미 (180g×3)
    6,980원

    공구핫딜 고래사
    5가지 야채듬뿍
    사각어묵(450g)
    4,330원

    닥터리브
    유기농 레몬스틱
    (20g×10개)
    9,900원

    공구핫딜 케라시스
    비듬케어 쿨링샴푸
    (각 1,000ml, 가려움완화/딥클린)
    각5,900원

    공구핫딜 퍼실 파워젤/
    실내건조 기획(각 2.7L+2.2L)
    각24,990원

    공구핫딜 실속형 종이컵(600P)/
    테이크아웃 종이컵(300P)
    9,900/15,900원

    2024 롯데마트와 롯데슈퍼가 매주 여러분의 경제적 소비생활을 지원합니다
    이번주 핫프라이스
    목초를 먹고 자란,
    건강한 닭이 낳은 달걀!
    풀무원 1+등급
    목초란
    (15입/대란/국산)
    롯데/KB국민/신한/삼성카드 2,000원 할인 4,990원
    ※기간 : 10/10(목) ~ 10/13(일), 4일간 ※10/15(월) ~ 10/16(수) 3일간 : 6,990원
    ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함
    ※KB국민BC/신한BC카드 제외 ※조기 품절될 수 있습니다

    ※정상가란? 2024년 9월 19일(목) ~ 10월 9일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한 BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함


  </div> 
  `,
  `
  <div>
    알뜰 장보기 골든 위크

    바나나
    (1.4kg 내외/송이/필리핀산)
    3,990원

    청도 홍시
    (10~12입/팩/국산)
    7,990원

    비파괴 당도선별
    샤인머스캣(대/송이/국산)
    ※1송이 구매시 6,990원
    2송이 이상 구매시 송이당 각5,990원

    오이맛고추
    (봉/국산)
    ※1봉 구매시 2,290원
    2봉 이상 구매시 1봉당 각1,790원

    동원 인기
    파우치죽 6종
    (각 420g)
    ※ 양반쇠고기죽/양반단호박죽/
    양반밤단팥죽/양반버섯야채죽/
    양반전복죽/수라고려인삼전복삼계죽
    ※동일금액상품 교차구매 가능
    각4,980~5,980원

    롯데 부여알밤 과자
    (상품별 용량 상이) ※롯데 빈츠 부여알밤 外
    각3,840~7,980원

    하림 깨끗한 농가에서
    직접 키운 자이언트
    토종닭 백숙용/볶음탕용
    (1.5kg/1.2kg, 냉장/국내산)
    14,900원

    1등급 한우
    국거리/불고기
    (각 100g/냉장/국내산 한우고기)
    ※해당 단일카드로 전액 결제시에 한함
    ※체크카드/앱카드 포함
    ※KB국민BC/신한BC카드 제외
    롯데/KB국민/신한/삼성카드 결제시 30%

    국내산 돼지 앞다리/뒷다리
    (국내산 돼지고기)
    L.POINT 30% 할인

    캐나다 인증 돼지고기
    삼겹살/목심(캐나다산)
    ※기간 : 10/10(목)~10/13(일), 4일간
    ※조기품절될 수 있습니다
    각1,590원

    제주 GAP 하우스 감귤/
    가을에 먹는
    제주 하우스 감귤
    (1.4kg, 팩, 국산/2kg, 박스, 국산)
    9,990/14,990원

    통영 홍가리비
    (700g/국산)
    5,990원

    1+1 해표 더 고소한김
    참기름/바삭돌김
    (4g×16봉/4.5g×16봉)
    ※원산지 별도표기 ※교차구매 가능
    각11,980원

    동원 간편한끼 훈제연어
    (150g/냉장/연어 : 칠레산)
    6,900원

    하나사면 하나 더 1+1

    1+1 하림 누룽지
    치킨텐더(400g)
    8,900원

    1+1 롯데 켄터키 핫도그
    (350g)
    8,990원

    1+1 오뚜기/삼양/
    하림/풀무원
    봉지라면 9종
    ※동일금액상품 교차구매 가능
    각4,290~8,800원

    1+1 동원 리챔(200g)
    5,090원

    1+1 해태 고향만두
    (320g)
    5,990원

    1+1 서울 고단백 치즈/
    짜지않아 건강한 치즈
    (각 180g) ※교차구매 가능
    각7,590원

    1+1 롯데 칠성사이다/
    칠성사이다제로/
    펩시콜라/
    펩시제로라임
    (각 1.25L)
    각3,580원

    1+1 롯데 팜앤홈
    오렌지/망고(각 1.2L)
    각4,480원

    1+1 수퍼보타닉
    바디워시(각 900ml)
    ※ 세이지&로즈마리/로즈&버베나
    각8,900원

    1+1 아우라 퍼퓸
    파라다이스리필
    (각 1.7L, 로즈/베르가못)
    각9,900원

    풀무원 냉장면 13종
    (상품별 상이) ※교차구매 가능
    2개 구매시 10,000원
    ※1개 구매시 각 5,990원

    롯데웰푸드
    식사이론 만두 3종(각 350g×2)
    ※ 메밀만두/단호박치킨만두/대파고기만두
    각8,490원

    오뚜기 콤비네이션/
    불고기 피자(원산지 별도표기)
    2개 구매시 10,000원
    ※1개 구매시 각 5,990원

    빙그레 삼다향
    감귤주스 3종
    (각 320ml, 한라봉/천혜향/청귤)
    3개 구매시 9,000원
    ※1개 구매시 각 3,500원

    24년 김장준비! 절임배추 사전예약
    ※기간 : 10/10(목)~10/16(수)
    ※고객 수령일 : 11/8(금)~12/21(토)
    ※매장 안내데스크에서 기간 선택
    ※매장 안내데스크에서 지금 바로 예약하세요!
    롯데/신한/KB국민/삼성/NH농협카드 5,000원 할인

    해남 절임배추(20kg/국산)
    ※해당 단일카드로 전액 결제시에 한함
    ※체크카드/앱카드 포함
    ※KB국민BC/신한 BC/NH농협BC카드 제외
    39,900원

    평창 절임배추
    (20kg/국산)
    64,900원

    절임 알타리(5kg/국산)/
    김치 양념(각 3.5kg/국산)
    ※경기도식/전라도식
    각39,900원

    맛있는 간편식 ※운영 점포에 한함

    경양식 함박세트
    (5입/원산지 별도표기)
    7,990원

    왕새우 튀김
    (8입/15입, 원산지 별도표기)
    5,490/9,990원

    불고기 비빔밥/
    고추장 불고기 비빔밥
    (원산지 별도표기)
    각5,490원

    ※정상가란? 2024년 9월 19일(목) ~ 10월 9일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한 BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
];

const fillZero = (width, str) => {
  return str.length >= width
    ? str
    : new Array(width - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
};

const getSuperData = () => {
  const dataArray = [];
  superData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데슈퍼 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getSuperNewData = () => {
  const dataArray = [];
  superNewData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데슈퍼 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const data = {
  L309: {
    title: "스마트전단지-슈퍼309",
    category: [],
    type: "SUPER",
    isFooter: false,
    imgPath: "images/202410_1/02",
    contents: getSuperData(),
  },
  L310: {
    title: "스마트전단지-슈퍼310",
    category: [],
    type: "SUPER",
    isFooter: false,
    imgPath: "images/202410_1/02",
    contents: getSuperData(),
  },
  L209: {
    title: "스마트전단지-슈퍼209",
    category: [],
    type: "SUPER",
    isFooter: false,
    imgPath: "images/202410_2/02",
    contents: getSuperNewData(),
  },
  L210: {
    title: "스마트전단지-슈퍼210",
    category: [],
    type: "SUPER",
    isFooter: false,
    imgPath: "images/202410_2/02",
    contents: getSuperNewData(),
  },
};

export default data;
