//링크
export const LEAFLET_URL = {
  a1: "https://www.lotteon.com/p/display/shop/seltDpShop/12919?mall_no=4",
  a2: "http://toysrus.lottemart.com",
  chu2020: "https://www.lotteon.com/p/display/shop/seltDpShop/29638",
  main: "http://www.lottemart.com",
};

export const IMAGE_PATH = `${process.env.REACT_APP_BASE_S3_IMAGE}/leaflet`;

/* 지난 전단지 reload */
export const leafletState = {
  // openDate는 오픈날짜로 변경(매주 목요일)
  MART: {
    openDate: "20241010",
    thisWeek: [
      101, //전점 GO(~10/09)
      103, //제타플렉스 GO(~10/09)
      718, //맥스 GO(~10/09)

      ////////////////////////////////////
      102, //전점 LMS(~10/09)
      104, //제타플렉스 LMS(~10/09)
      719, //맥스 LMS(~10/09)

      105, //제타플렉스 서울역점 GO(~10/09)
      106, //제타플렉스 서울역점 LMS(~10/09)

      //  작업 x
      601, //고정 (생활공감)
      901, //고정 (룸바이홈)

      // 테스트용 고정
      999,
    ],
    willThisWeek: [
      201, //전점 GO(~10/16)
      203, //제타플렉스 GO(~10/16)
      718, //맥스 GO(~10/16)

      ////////////////////////////////////
      202, //전점 LMS(~10/16)
      204, //제타플렉스 LMS(~10/16)
      719, //맥스 LMS(~10/16)

      205, //제타플렉스 서울역점 GO(~10/16)
      206, //제타플렉스 서울역점 LMS(~10/16)

      //  작업 x
      601, //고정 (생활공감)
      901, //고정 (룸바이홈)

      // 테스트용 고정
      999,
    ],
  },
  // 롯데슈퍼 대표 전단
  // 성수연 담당 요청 (24.03.28) 슈퍼 LMS 추가
  SUPER: {
    openDate: "20241010",
    thisWeek: [
      309, //슈퍼(~10/09)
      310, //슈퍼 LMS (~10/09)
    ],
    willThisWeek: [
      209, //슈퍼(~10/16)
      210, //슈퍼 LMS (~10/16)
    ],
  },
  // 롯데슈퍼 추가 전단(설,명절 등)
  /*SUPER_THEME: {
    openDate: "20230831",
    thisWeek: [505],
    willThisWeek: [105],
  },*/
};
