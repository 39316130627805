import { LEAFLET_URL } from "../constants";

const allStoreData = [
  `
  <h1>LOTTE Mart</h1>
  <div>
    ·전단적용기간 : 2024. 10. 10(목) ~ 2024. 10. 16(수) < 비신선식품 적용기간 : 2024. 10. 1(화) ~ 10. 16(수) >

    고객 여러분의 밥상물가를 지킬 10대 아이템 미션! 물가를 잡아라
    롯데마트&롯데슈퍼가 물가안정에 앞장섭니다.
    ※기간 : 10월 10일 목요일부터 10월 16일 수요일까지 (비신선식품 적용은 10월 1일 화요일부터 10월 16일 수요일까지)

    L.POINT 30% 서해안 꽃게(100g/국산) 1,113원
    GAP 친환경 느타리 버섯(500g/팩/국산) 1,990원

    제주 흑돼지 블랙페스타
    L.POINT 최대 50%
    요리하다 제주 흑돼지 불고기
    (각 1kg/냉장/원산지 별도표기)
    ※간장/고추장

    1+1 풀무원 서울라면/서울짜장 (각 4개입) ※교차구매 가능 각 5,450원

    1+1
    떠먹는 요구르트 30종
    (각 80g×4입/각 85g×4입)
    ※빙그레/풀무원/남양/매일/일동 外
    ※동일 브랜드 內 동일 가격 간 교차구매 가능
    3,780 ~ 6,480원

    공구핫딜
    구운 아몬드/마카다미아
    (800g/350g, 원산지 별도표기)
    ※조기 품절될 수 있습니다
    각 9,990원

    1+1
    녹차원 꿀 유자차/
    레몬생강차 外 3종
    (각 1kg) ※교차구매 가능
    각 9,900원

    1+1
    테크 실내건조 베이킹+구연산
    (각 3.4L, 일반/드럼) ※교차구매 가능
    각 19,800원

    롯데/KB국민/신한/NH농협/삼성카드 2개 이상 50%
    잘풀리는집 굿딜
    3겹 화장지(30m×30롤)
    ※페이지 하단 카드할인 세부내용 참고
    2개 이상 구매시 1개당 각 13,900원 ※1개 구매시 27,800원

    공구핫딜 공동구매 가계절약
    공구핫딜은 롯데마트와 슈퍼가
    공동 기획한 알뜰 한정 상품 입니다

    공구핫딜 해태 홈런볼
    15% 중량업(148g) 3,280원

    공구핫딜 칠성사이다(190ml×30입)
    14,490
    개당 483원

    공구핫딜 코카콜라(215ml×30입)
    18,360원
    개당 612원

    공구핫딜
    하림 치킨너겟
    증량기획(480g+300g)
    9,980원

    10+2 기획 공구핫딜 고래사
    꼬치어묵 증량 기획(526g)
    6,940원

    2+1 기획 공구핫딜 한성
    와일드크래미(180g×3)
    6,980원

    100g 증량
    공구핫딜 롯데
    비엔나소시지(600g)
    7,980원

    7+2 기획 공구핫딜 뉴트리원
    이뮨 플러스 7+2
    (21.5g×9병)
    13,900원

    4입 기획 공구핫딜 2080
    로즈핑크 치약 기획
    (150g×4입)
    6,900원

    2.2L 추가증정 공구핫딜 퍼실 파워젤/
    실내건조 기획(각 2.7L+2.2L)
    각24,990원

    공구핫딜 실속형 종이컵(600P) 9,900원
    테이크아웃 종이컵(300P) 15,900원

    2024 롯데마트가 매주 여러분의 경제적 소비생활을 지원합니다
    이번주 핫프라이스
    롯데마트 연중 최저가 도전!
    '빨래 고민 해결사' 1개당 400원대!
    ※연중기간 : 23년 9월 ~ 24년 10월
    퍼실 디스크 4종 (각 26개입) ※파워/라벤더/실내건조/컬러
    롯데/KB국민/신한/NH농협/삼성카드 1+1 21,900원
    ※기간 : 10/10(목) ~ 10/16(수) ※교차구매 가능
    ※점별 운영상품 상이 ※페이지 하단 카드할인 세부내용 참고

    ※정상가란? 2024년 9월 19일(목) ~ 10월 9일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
  `
  <div>
    와규 블랙페스타 청정와규 전품목 롯데/신한카드 결제시 최대 50%
    (100g/냉장/호주산) ※조기 품절될 수 있습니다
    ※페이지 하단 카드할인 세부내용 참고

    한우 국거리/불고기(1등급)
    (각 100g/냉장/국내산 한우고기)
    L.POINT 40%

    L.POINT 50% 삼겹살/목심
    (각 100g/냉장/캐나다산)
    ※조기 품절될 수 있습니다
    ※일부점 제외
    990원
    ※기간 : 10/10(목)~10/13(일), 4일간
    ※1인 2팩 구매한정

    L.POINT 5,000원 깨끗한 농가에서
    직접 키운 자이언트
    토종닭 백숙/볶음탕용
    (각 1.5kg/1.2kg, 냉장, 국내산)
    각14,900원

    1+1
    동원 간편한끼 훈제연어
    (150g/냉장/연어 : 칠레산)
    11,900원

    수산물 구매금액의 1%가
    지속가능한 어업활동에
    기부됩니다

    고등어(2마리/생물/국산)
    ※산지 시황에 따라 조기 품절될 수 있습니다
    6,980원

    L.POINT 30% 통영
    홍가리비
    (100g/국산)
    623원

    L.POINT 50% 코주부 징기스칸 육포
    순한맛/매운맛
    (각 130g/원산지 별도표기)
    각 7,450원

    롯데/KB국민/신한/NH농협/삼성카드 5,000원 할인
    해남절임 배추(20kg/국산)
    ※기간 : 10/7(월)~10/13(일), 7일간
    ※한정수량 소진시 카드할인 종료될 수 있습니다
    ※ 페이지 하단 카드할인 세부내용 참고
    39,900원

    친환경 어린잎채소(90g×2팩/국산)
    2,990원

    온가족 인삼(난발 500g/팩/국산)/
    유명산지 금산인삼(50g/팩/국산)
    17,900/4,990원

    바나나
    (1.4kg 내외/송이/필리핀산)
    3,990원

    2송이 이상 각 1,000원 할인
    비파괴 당도선별
    샤인머스캣(대/송이/국산)
    2송이 이상 구매시 1송이당 각4,990원
    ※1송이 구매시 5,990원

    제주 GAP 하우스 감귤(1.4kg/팩/국산)/
    가을에 먹는 제주 하우스 감귤
    (2kg/박스/국산)
    9,990/14,990원

    청도 홍시
    (10~12입/팩/국산)
    8,990원

    L.POINT 30% ALL NEW 새우초밥
    (20입/1팩/원산지 별도표기)
    13,993원

    꿀간장/꿀사과 닭강정
    (각 1팩/원산지 별도표기)
    각7,990원

    더 커진 깐쇼새우/
    크리스피 허니 쉬림프
    (각 1팩/원산지 별도표기)
    각6,990원

    L.POINT 20% 비빔밥 3종
    (각 1팩/원산지 별도표기)
    ※강된장제육,불고기듬뿍,고추장불고기
    각4,792원

    1,500원 할인 부드러운 호밀빵
    (8입/1팩/원산지 별도표기)
    ※베이커리팩토리/쁘띠르뽀미에 입점점에 한함
    5,900원

    하나사면 하나 더 1+1

    1+1 동원 통그릴
    부어스트(500g) 8,980원

    1+1 CJ 맥스봉 닭가슴살(420g)/
    동그란 스팸(160g) ※동일상품에 한함
    8,480/4,980원

    1+1 하림 텐더스틱
    (550g) 11,480원

    1+1 마니커 소금구이
    통날개구이(400g)
    11,980원

    1+1 청정원 맛선생 국물내기
    한알 4종(상품별 규격 상이)
    ※멸치디포리/야채/사골/황태 ※교차구매 가능
    각9,900원

    1+1 하림 더미식 상온 국물요리 6종
    (부대찌개 外 5종, 각 350g) ※교차구매 가능
    각5,980원

    1+1 동원 양반 용기죽 6종(상품별 상이) ※교차구매 가능
    각3,980원

    1+1 환절기 기능성 유아음료 16종
    (상품별 규격 상이) ※동일 브랜드/동일 가격 교차구매 가능
    1,500~4,900원

    1+1 16입 두유 전품목
    (각 190ml×16입)
    ※동일 시리즈 상품간 교차구매 가능
    14,900~20,000원

    1+1 맥심 TOP 마스터라떼 外 4종(각 275ml)
    ※스위트아메리카노/더블랙/스모키라떼/스모키 블랙 ※교차구매 가능
    각2,380원

    1+1 청우 찰떡쿠키 오리지널(258g)
    5,690원

    1+1 삼립 누네띠네
    오리지널(60g) 2,980원

    2개 이상 구매시 더 싸게

    2개 이상 각 1,000원 할인 CJ 비비고 통새우만두/
    쌀떡볶이(상품별 규격 상이)
    2개 이상 구매시 1개당 각5,980원
    ※1개 구매시 각 6,980원 ※교차구매 가능

    2개 이상 구매시 각 1,980원 할인
    풀무원 냉장면 6종
    (각 2인/상품별 용량 상이)
    ※우동/수제비/도삭면
    2개 이상 구매시 1개당 각5,000원
    ※1개 구매시 각 6,980원 ※교차구매 가능

    2개 이상 구매시 각 3,980원 할인
    풀무원 노엣지피자/
    핫도그 6종(상품별 상이)
    2개 이상 구매시 1개당 각5,000원
    ※1개 구매시 각 8,980원 ※교차구매 가능

    2개 이상 10%
    농심 봉지라면
    전품목 42종(상품별 규격 상이)
    ※올리브 짜파게티 5개입 外 41종
    2개 이상 구매시 1개당 각1,422~14,040원
    ※1개 구매시 각 1,580~15,600원 ※교차구매 가능

    2개 이상 50%
    샘표 고추장/된장/쌈장
    전품목 11종(상품별 용량 상이)
    2개 이상 구매시 1개당 각1,245~16,490원
    ※1개 구매시 각 2,490~32,980원 ※교차구매 가능

    2개 이상 20%
    풀무원 다논
    그릭 플레인 外 2종(각 90g×8입)
    2개 이상 구매시 1개당 각5,584원
    ※1개 구매시 각 6,980원 ※교차구매 가능

    2개 이상 50%
    매일 뼈로가는 칼슘치즈(270g)
    2개 이상 구매시 1개당 각4,990원
    ※1개 구매시 9,980원

    2개 이상 10%
    켈로그 그래놀라 5종
    (상품별 용량 상이)
    ※켈로그 크랜베리 그래놀라 外
    2개 이상 구매시 1개당 각5,922원
    ※1개 구매시 각 6,580원 ※교차구매 가능

    2개 이상 20%
    제주 삼다수 그린
    (2L×6입/500ml×20입) ※제주점 제외
    2개 이상 구매시 1개당 각5,184/7,680원
    ※1개 구매시 각 6,480/9,600원 ※교차구매 가능

    ※정상가란? 2024년 9월 19일(목) ~ 10월 9일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    동원 해당상품
    2개 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※'도와드리겠습니다'센터 증정
    ※구분자 "㉢"를 확인하세요

    동원 치킨 5종(상품별 상이)
    ※순살/양념순살/할라피뇨/스윗허니/치킨봉
    각8,980원

    오뚜기 XO교자 3종
    (상품별 상이)
    ※새우&홍게살/고기/김치
    각8,980원

    CJ 더건강한
    안심비엔나
    (200g×2)
    7,480원

    사조대림 잠룽 부어스트
    (705g)
    8,980원

    풀무원
    국물떡볶이(423.5g)
    3,280원

    CJ 가쓰오
    우동(4인)
    8,980원

    롯데 식사이론 만두 3종(각 700g)
    ※대파고기/단호박닭가슴살/메밀부추
    각8,480원

    L.POINT 4,500원
    동원 살코기 마일드 참치기획
    (90g×3입+90g×3입)
    9,980원

    L.POINT 40%
    하인즈 파스타소스 5종(각 350g)
    ※베이컨 토마토 外 4종 ※점별 운영상품 상이
    각3,588~3,888원

    L.POINT 각 1,000원
    남양 마시는 불가리스
    사과 外 4종(각 150ml×8입)
    각8,980원

    4입기획 개당 1,470원
    공구핫딜 팜앤홈
    제주감귤(1.2L×4입)
    5,880원

    16+8입 기획 개당 562원
    공구핫딜 베지밀 검은참깨
    고칼슘두유(190ml×16+8입)
    13,480원

    동원 소와나무
    순수우유 기획
    (900ml×2입)
    3,980원

    롯데마트 단독
    매일 바이오 그릭
    ×그래놀라 기획(400g×2입)
    8,980원

    빅사이즈 27입 공구핫딜 롯데 초코파이
    (40g×27입) ※점별 운영상품 상이
    8,880원

    켈로그 프링글스
    미니캔 번들
    (636g/오리지날 6입, 양파맛 6입)
    ※점별 운영상품 상이
    11,980원

    롯데 ABC 초콜릿(187g)
    4,480원

    롯데 부여알밤 과자/디저트빵 8종
    (상품별 용량 상이) ※롯데 빈츠 부여알밤 外 ※점별 운영상품 상이
    각1,580~7,980원

    각 2,500원할인
    슈퍼너츠 피넛버터 2종
    (각 460g, 스무스/크런치)
    각11,900원

    L.POINT 5,000원
    종근당건강
    락토핏 골드
    90포(2g×90포)
    17,900원

    엘지생활건강
    3만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 "ⓩ"을 확인하세요
    ※일부품목 상품권 증정 행사 제외

    홈스타 맥스프레시 4종(각 900ml)
    ※욕실용/곰팡이/주방용/착붙는락스
    ※1개 구매시 각7,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각3,950원

    아우라 퍼퓸 리필
    (각 1.7L, 로즈/베르가못)
    ※1개 구매시 각9,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각4,950원

    페리오 토탈7
    인텐스 치약 2종
    (리프레쉬쿨링/프리징쿨링)
    ※점별 운영상품 상이
    ※1개 구매시 각13,980원 ※교차구매 가능
    2개 이상 구매시 1개당 각6,990원

    페리오 이지클리닉 칫솔 2종
    (초극세모/탄력모) ※점별 운영상품 상이
    ※1개 구매시 각8,900원 ※교차구매 가능
    2개 이상 구매시 각4,450원

    엘지생활건강
    클렌징 폼 전품목
    (상품별 상이) ※점별 운영상품 상이
    ※일부 재고소진 품목 제외
    ※일부품목 상품권 증정 행사 제외
    ※1개 구매시 각7,900~13,800원 ※교차구매 가능
    2개 이상 구매시 1개당 각3,950~6,900원

    히말라야 핑크솔트
    바디워시(각 900g)
    ※점별 운영상품 상이
    ※1개 구매시 각14,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각7,450원

    엘라스틴 아미노 프로틴
    클리닉 샴푸/컨디셔너
    (각 855ml, 손상/볼륨)
    ※점별 운영상품 상이
    ※1개 구매시 각13,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각6,950원

    리엔 보윤 샴푸/
    컨디셔너 3종
    (각 1,000ml) ※점별 운영상품 상이
    각9,540원

    헨켈 그린 행사상품
    2만 5천원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " ㉠ "을 확인하세요

    퍼실 그린파워
    주방세제 4종
    (상품별 상이)
    ※레몬그라스/알로에베라
    ※1개 구매시 각9,500원 ※교차구매 가능
    2개 이상 구매시 1개당 각4,750원

    모나리자 행사상품
    2만 5천원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " ㉤ "을 확인하세요

    모나리자
    코튼화이트
    (30m×30롤)
    ※1개 구매시 30,800원
    2개 이상 구매시 1개당 각15,400원

    쏘피 생리대/마미포코 행사상품
    3만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " ㉦ "을 확인하세요

    쏘피 유기농 생리대
    (상품별 상이) ※중/대/슈퍼롱
    ※1개 구매시 각16,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각8,450원

    하기스 기저귀 행사상품
    10만원 이상 구매시
    10,000원
    롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " ㉡ "을 확인하세요

    하기스 맥스드라이/네이처메이드
    19종(상품별 상이) ※점별 운영상품 상이
    ※1개 구매시 각30,600~45,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각19,890~29,840원

    2리터 추가기획 공구핫딜
    순샘 레몬
    (2L+2L)
    7,900원

    2리터 추가기획 공구핫딜
    고농축피죤 기획
    (파우더라일락, 2L+2L)
    13,900원

    공구핫딜 케라시스
    비듬케어 쿨링샴푸
    (각 1,000ml, 딥클린/가려움완화)
    각5,900원

    공구핫딜
    샤워메이트
    플라워퍼퓸
    바디워시 옐로우
    (프리지아&쟈스민향 1,200ml)
    4,900원

    롯데/KB국민/신한/NH농협/삼성카드 10%
    피지오겔/바이오더마/세타필
    더마 스킨케어 5종(상품별 상이)
    ※페이지 하단 카드할인 세부내용 참고 ※점별 운영상품 상이
    각17,010~24,219원

    도브 바디로션 3종
    (각 400ml) ※점별 운영상품 상이
    ※너리시드/셔플바운스/라이트
    ※1개 구매시 각15,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각7,950원

    존슨즈 베이비로션 3종
    (상품별 상이) ※점별 운영상품 상이
    ※핑크 500ml+200ml, 화이트/베드타임 각 500ml
    ※1개 구매시 각17,900~21,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각8,950~10,950원

    크리넥스 마이비데 물티슈 전품목
    (상품별 상이) ※점별 운영상품 상이 ※L.POINT회원에 한함
    각1,813~10,430원

    다우니 세탁세제 4종(각 3.2L)
    ※실내건조/헹굼제로/프레시/라벤더
    ※1개 구매시 각35,900~36,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각17,950~18,450원

    ※정상가란? 2024년 9월 19일(목) ~ 10월 9일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    가을특가 리빙 플렉스

    에브리데이쿡 프라이팬 4종/
    뉴이모션 프라이팬 5종(상품별 상이)
    18,480~39,480원

    3M 다목적 수세미/
    제로스크레치 수세미(각 10입)
    ※3M 상품권 증정행사 미해당 품목
    6,390/8,790원

    유니랩 위생장갑/위생롤백(중/대/특대형)
    2,570~4,180원

    더좋은 부탄가스(4입)
    5,000원

    쿡셀 전상품(상품별 상이)
    ※점별 입점상품 상이 ※행사상품에 한함 ※연출된 이미지 컷입니다
    각31,120~50,240원

    락앤락 김치통 2P기획 4종
    (2.6L/3.6L/4.5L/8L)
    각9,900~21,000원

    오늘좋은 극세사 누빔패드
    (싱글 110×200cm/퀸 150×220cm)
    ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이
    24,430~27,930원

    오늘좋은 세탁이 편리한 베개
    (40×60cm/50×70cm)
    ※1개 구매시 각 21,900/29,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각10,950/14,950원

    룸바이홈 쿠션/방석
    (상품별 상이) ※룸바이홈 말랑 사각방석 외
    ※입점점에 한함 ※점별 재고상이 ※해당상품에 한함
    10,000/15,000/20,000원

    오늘좋은×공구핫딜
    베이직 거실화(각 2P)
    ※입점점에 한함 ※점별 재고 상이
    각9,900원

    디클린 정수필터 4종(상품별 상이)
    ※입점점에 한함 ※점별 재고 상이 ※해당상품에 한함
    7,560~18,610원

    파크론 고급 발코니매트(2P, 딥그레이)/
    파크론 실키 놀이방 매트(심플스퀘어)
    ※150×200cm ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이
    37,520/39,920원

    3M 해당상품
    2만5천원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※'도와드리겠습니다'센터 증정
    ※구분자 " M "를 확인하세요

    3M 리싸이클
    정전기 청소포(각 60매)
    (표준형/대형) ※입점점에 한함
    ※해당상품에 한함 ※점별 재고 상이
    8,680/9,910원

    오늘좋은 4륜
    우드탑 테이블 카트
    (42×37×50~100cm)
    ※입점점에 한함
    36,720원

    공구핫딜 절전형
    멀티탭 1+1기획
    (3구 2M)
    ※입점점에 한함 ※점별 재고 상이
    19,900원

    엑스가드 레저소품(상품별 상이)
    ※귀걸이 멀티프, 트레이닝 장갑 外
    5,900~17,900원

    오늘좋은 히트 동내의(상품별 상이)
    7,900~15,900원

    트래커 여행가방(기내용 16/20인치, 화물용 24/28인치)
    ㆍ확장지퍼로 수납력 확대!
    ㆍ 더블 4휠 바퀴 장착으로 이동시 저소음!
    59,000~99,000원

    허밋 원터치 텐트
    (2~3인용/3~4인용) ※입점점에 한함
    39,900/49,900원

    토이저러스 인기완구 L.POINT 최대 20%
    ※기간 : 10/1(화)~10/16(수)

    스위치 슈퍼마리오브라더스
    원더 外 9종(전체 이용가)
    ※입점점에 한함 ※점별 재고 상이
    각54,800원

    헬로카봇 80종
    (상품별 상이)
    각16,000~150,400원

    콜리올리
    반려동물 식/용품
    해당 상품 최대 30%
    ※기간 : 10/1(화)~10/16(수)

    콜리올리
    콜라겐 시리즈 간식
    (상품별 상이) ※점별 재고 상이
    2,723~3,913원

    콜리올리 방석/하우스 4종
    (상품별 상이) ※점별 재고 상이
    18,323~31,920원

    세계맥주 골라담기
    (38종/상품별 용량 상이)
    6캔 구매시 12,000원
    ※1캔 구매시 3,000원 ※4캔 구매시 9,000원

    코젤 보드게임 패키지
    (500ml×6)
    15,980원

    몬테스 알파
    까베르네 소비뇽/
    메를로(각 750ml)
    각28,800원

    버팔로트레이스
    (750ml)
    45,900원

    요리하다 온가족 먹거리 추천!

    요리하다 허니
    바사삭치킨(375g)
    5,990원

    요리하다×두끼 즉석떡볶이/
    마라떡볶이(560g/520g)
    각4,990원

    요리하다 꽃게탕(660g)
    11,900원

    요리하다 정통
    가쓰오우동(484g)
    4,990원

    오늘 가장 좋은 선택 오늘 좋은
    좋은 상품을 최적의 가격에, 롯데전문MD.롯데중앙연구소 PB개발 노하우로 만든 대표 브랜드

    오늘좋은 오리지널
    &카라멜 팝콘(280g)
    2,990원

    오늘좋은 버터갈릭 새우칩/
    블랙패퍼쉬림프 새우칩(각 155g)
    각2,000원

    오늘좋은 먹태칩(50g)/
    옥수수 똘뱅이(195g)
    1,000/2,000원

    오늘좋은×고래사
    종합모둠어묵탕(700g)
    6,990원

    ※정상가란? 2024년 9월 19일(목) ~ 10월 9일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
];

const zettaData = [
  `
  <h1>LOTTE Mart ZETTAPLEX</h1>
  
  <div>
    ·전단적용기간 : 2024. 10. 10(목) ~ 2024. 10. 16(수) < 비신선식품 적용기간 : 2024. 10. 1(화) ~ 10. 16(수) >

    고객 여러분의 밥상물가를 지킬 10대 아이템 미션 물가를 잡아라
    롯데마트&롯데슈퍼가 물가안정에 앞장섭니다.
    ※기간 : 10월 10일 목요일부터 10월 16일 수요일까지 (비신선식품 적용은 10월 1일 화요일부터 10월 16일 수요일까지)

    서해안 꽃게(100g/국산)
    1,113원

    GAP 친환경
    느타리 버섯(500g/팩/국산)
    1,990원


    L.POINT 최대 50%
    제주 흑돼지 블랙페스타
    요리하다 제주 흑돼지 불고기
    (각 1kg/냉장/원산지 별도표기)
    ※간장/고추장

    1+1
    풀무원 서울라면/서울짜장
    (각 4개입) ※교차구매 가능
    각5,450원

    1+1
    떠먹는 요구르트 30종
    (각 80g×4입/각 85g×4입)
    ※빙그레/풀무원/남양/매일/일동 外
    ※동일 브랜드 內 동일 가격 간 교차구매 가능
    3,780~6,480원

    공구핫딜
    구운 아몬드/마카다미아
    (800g/350g, 원산지 별도표기)
    ※조기 품절될 수 있습니다
    각9,990원

    1+1
    녹차원 꿀 유자차/
    레몬생강차 外 3종
    (각 1kg) ※교차구매 가능
    각9,900원

    1+1
    삼진어묵 시원한 모듬어묵/
    쫄깃한 야채사각(456g/200g)
    ※동일 상품에 한함
    7,480/3,280원

    1+1
    테크 실내건조 베이킹+구연산
    (각 3.4L, 일반/드럼) ※교차구매 가능
    각19,800원

    롯데/KB국민/신한/NH농협/삼성카드 2개 이상 50%
    잘풀리는집 굿딜
    3겹 화장지(30m×30롤)
    ※페이지 하단 카드할인 세부내용 참고
    2개 이상 구매시 1개당 각13,900원
    ※1개 구매시 27,800원

    공구핫딜 공동구매 가계절약
    공구핫딜은 롯데마트와 슈퍼가 공동 기획한 알뜰 한정 상품 입니다

    공구핫딜 해태 홈런볼
    15% 중량업(148g)
    3,280원

    공구핫딜 칠성사이다(190ml×30입) 14,490원 개당 483원
    공구핫딜 코카콜라(215ml×30입) 18,360원 개당 612원

    공구핫딜
    하림 치킨너겟
    증량기획(480g+300g)
    9,980원

    10+2 기획 공구핫딜 고래사
    꼬치어묵 증량 기획(526g)
    6,940원

    2+1 기획 공구핫딜 한성
    와일드크래미(180g×3)
    6,980원

    공구핫딜 롯데
    비엔나소시지(600g)
    7,980원

    공구핫딜 뉴트리원
    이뮨 플러스 7+2
    (21.5g×9병)
    13,900원

    공구핫딜 2080
    로즈핑크 치약 기획
    (150g×4입)
    6,900원

    공구핫딜 퍼실 파워젤/
    실내건조 기획(각 2.7L+2.2L)
    각24,990원

    공구핫딜 실속형 종이컵(600P)/
    테이크아웃 종이컵(300P)
    9,900/15,900원

    2024 롯데마트가 매주 여러분의 경제적 소비생활을 지원합니다
    이번주 핫프라이스
    롯데마트 연중 최저가 도전!
    '빨래 고민 해결사' 1개당 400원대!
    퍼실 디스크 4종
    (각 26개입)
    ※파워/라벤더/실내건조/컬러
    '빨래 고민 해결사' 1개당 400원대!
    ※연중기간 : 23년 9월 ~ 24년 10월
    롯데/KB국민/신한/NH농협/삼성카드 1+1
    21,900원
    ※기간 : 10/10(목) ~ 10/16(수) ※교차구매 가능
    ※점별 운영상품 상이 ※페이지 하단 카드할인 세부내용 참고

    ※정상가란? 2024년 9월 19일(목) ~ 10월 9일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    와규 블랙페스타 청정와규 전품목 롯데/신한카드 결제시 최대 50%
    (100g/냉장/호주산) ※조기 품절될 수 있습니다
    ※페이지 하단 카드할인 세부내용 참고

    한우 국거리/불고기(1등급)
    (각 100g/냉장/국내산 한우고기)
    L.POINT 40%

    L.POINT 50% 삼겹살/목심
    (각 100g/냉장/캐나다산)
    ※조기 품절될 수 있습니다
    ※일부점 제외
    990원
    ※기간 : 10/10(목)~10/13(일), 4일간
    ※1인 2팩 구매한정

    L.POINT 5,000원 깨끗한 농가에서
    직접 키운 자이언트
    토종닭 백숙/볶음탕용
    (각 1.5kg/1.2kg, 냉장, 국내산)
    각14,900원

    1+1
    동원 간편한끼 훈제연어
    (150g/냉장/연어 : 칠레산)
    11,900원

    수산물 구매금액의 1%가
    지속가능한 어업활동에
    기부됩니다

    고등어(2마리/생물/국산)
    ※산지 시황에 따라 조기 품절될 수 있습니다
    6,980원

    L.POINT 30% 통영
    홍가리비
    (100g/국산)
    623원

    L.POINT 50% 코주부 징기스칸 육포
    순한맛/매운맛
    (각 130g/원산지 별도표기)
    각 7,450원

    롯데/KB국민/신한/NH농협/삼성카드 5,000원 할인
    해남절임 배추(20kg/국산)
    ※기간 : 10/7(월)~10/13(일), 7일간
    ※한정수량 소진시 카드할인 종료될 수 있습니다
    ※ 페이지 하단 카드할인 세부내용 참고
    39,900원

    친환경 어린잎채소(90g×2팩/국산)
    2,990원

    온가족 인삼(난발 500g/팩/국산)/
    유명산지 금산인삼(50g/팩/국산)
    17,900/4,990원

    바나나
    (1.4kg 내외/송이/필리핀산)
    3,990원

    2송이 이상 각 1,000원 할인
    비파괴 당도선별
    샤인머스캣(대/송이/국산)
    2송이 이상 구매시 1송이당 각4,990원
    ※1송이 구매시 5,990원

    제주 GAP 하우스 감귤(1.4kg/팩/국산)/
    가을에 먹는 제주 하우스 감귤
    (2kg/박스/국산)
    9,990/14,990원

    청도 홍시
    (10~12입/팩/국산)
    8,990원

    L.POINT 30% ALL NEW 새우초밥
    (20입/1팩/원산지 별도표기)
    13,993원

    꿀간장/꿀사과 닭강정
    (각 1팩/원산지 별도표기)
    각7,990원

    더 커진 깐쇼새우/
    크리스피 허니 쉬림프
    (각 1팩/원산지 별도표기)
    각6,990원

    L.POINT 20% 비빔밥 3종
    (각 1팩/원산지 별도표기)
    ※강된장제육,불고기듬뿍,고추장불고기
    각4,792원

    1,500원 할인 부드러운 호밀빵
    (8입/1팩/원산지 별도표기)
    ※베이커리팩토리/쁘띠르뽀미에 입점점에 한함
    5,900원

    하나사면 하나 더 1+1

    1+1 동원 통그릴
    부어스트(500g) 8,980원

    1+1 CJ 맥스봉 닭가슴살(420g)/
    동그란 스팸(160g) ※동일상품에 한함
    8,480/4,980원

    1+1 하림 텐더스틱
    (550g) 11,480원

    1+1 마니커 소금구이
    통날개구이(400g)
    11,980원

    1+1 청정원 맛선생 국물내기
    한알 4종(상품별 규격 상이)
    ※멸치디포리/야채/사골/황태 ※교차구매 가능
    각9,900원

    1+1 하림 더미식 상온 국물요리 6종
    (부대찌개 外 5종, 각 350g) ※교차구매 가능
    각5,980원

    1+1 동원 양반 용기죽 6종(상품별 상이) ※교차구매 가능
    각3,980원

    1+1 환절기 기능성 유아음료 16종
    (상품별 규격 상이) ※동일 브랜드/동일 가격 교차구매 가능
    1,500~4,900원

    1+1 16입 두유 전품목
    (각 190ml×16입)
    ※동일 시리즈 상품간 교차구매 가능
    14,900~20,000원

    1+1 맥심 TOP 마스터라떼 外 4종(각 275ml)
    ※스위트아메리카노/더블랙/스모키라떼/스모키 블랙 ※교차구매 가능
    각2,380원

    1+1 청우 찰떡쿠키 오리지널(258g)
    5,690원

    1+1 삼립 누네띠네
    오리지널(60g) 2,980원

    2개 이상 구매시 더 싸게

    2개 이상 각 1,000원 할인 CJ 비비고 통새우만두/
    쌀떡볶이(상품별 규격 상이)
    2개 이상 구매시 1개당 각5,980원
    ※1개 구매시 각 6,980원 ※교차구매 가능

    2개 이상 구매시 각 1,980원 할인
    풀무원 냉장면 6종
    (각 2인/상품별 용량 상이)
    ※우동/수제비/도삭면
    2개 이상 구매시 1개당 각5,000원
    ※1개 구매시 각 6,980원 ※교차구매 가능

    2개 이상 구매시 각 3,980원 할인
    풀무원 노엣지피자/
    핫도그 6종(상품별 상이)
    2개 이상 구매시 1개당 각5,000원
    ※1개 구매시 각 8,980원 ※교차구매 가능

    2개 이상 10%
    농심 봉지라면
    전품목 42종(상품별 규격 상이)
    ※올리브 짜파게티 5개입 外 41종
    2개 이상 구매시 1개당 각1,422~14,040원
    ※1개 구매시 각 1,580~15,600원 ※교차구매 가능

    2개 이상 50%
    샘표 고추장/된장/쌈장
    전품목 11종(상품별 용량 상이)
    2개 이상 구매시 1개당 각1,245~16,490원
    ※1개 구매시 각 2,490~32,980원 ※교차구매 가능

    2개 이상 20%
    풀무원 다논
    그릭 플레인 外 2종(각 90g×8입)
    2개 이상 구매시 1개당 각5,584원
    ※1개 구매시 각 6,980원 ※교차구매 가능

    2개 이상 50%
    매일 뼈로가는 칼슘치즈(270g)
    2개 이상 구매시 1개당 각4,990원
    ※1개 구매시 9,980원

    2개 이상 10%
    켈로그 그래놀라 5종
    (상품별 용량 상이)
    ※켈로그 크랜베리 그래놀라 外
    2개 이상 구매시 1개당 각5,922원
    ※1개 구매시 각 6,580원 ※교차구매 가능

    2개 이상 20%
    제주 삼다수 그린
    (2L×6입/500ml×20입) ※제주점 제외
    2개 이상 구매시 1개당 각5,184/7,680원
    ※1개 구매시 각 6,480/9,600원 ※교차구매 가능

    ※정상가란? 2024년 9월 19일(목) ~ 10월 9일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    동원 해당상품
    2개 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※'도와드리겠습니다'센터 증정
    ※구분자 "㉢"를 확인하세요

    동원 치킨 5종(상품별 상이)
    ※순살/양념순살/할라피뇨/스윗허니/치킨봉
    각8,980원

    오뚜기 XO교자 3종
    (상품별 상이)
    ※새우&홍게살/고기/김치
    각8,980원

    CJ 더건강한
    안심비엔나
    (200g×2)
    7,480원

    사조대림 잠룽 부어스트
    (705g)
    8,980원

    풀무원
    국물떡볶이(423.5g)
    3,280원

    CJ 가쓰오
    우동(4인)
    8,980원

    롯데 식사이론 만두 3종(각 700g)
    ※대파고기/단호박닭가슴살/메밀부추
    각8,480원

    L.POINT 4,500원
    동원 살코기 마일드 참치기획
    (90g×3입+90g×3입)
    9,980원

    L.POINT 40%
    하인즈 파스타소스 5종(각 350g)
    ※베이컨 토마토 外 4종 ※점별 운영상품 상이
    각3,588~3,888원

    L.POINT 각 1,000원
    남양 마시는 불가리스
    사과 外 4종(각 150ml×8입)
    각8,980원

    4입기획 개당 1,470원
    공구핫딜 팜앤홈
    제주감귤(1.2L×4입)
    5,880원

    16+8입 기획 개당 562원
    공구핫딜 베지밀 검은참깨
    고칼슘두유(190ml×16+8입)
    13,480원

    동원 소와나무
    순수우유 기획
    (900ml×2입)
    3,980원

    롯데마트 단독
    매일 바이오 그릭
    ×그래놀라 기획(400g×2입)
    8,980원

    빅사이즈 27입 공구핫딜 롯데 초코파이
    (40g×27입) ※점별 운영상품 상이
    8,880원

    켈로그 프링글스
    미니캔 번들
    (636g/오리지날 6입, 양파맛 6입)
    ※점별 운영상품 상이
    11,980원

    롯데 ABC 초콜릿(187g)
    4,480원

    롯데 부여알밤 과자/디저트빵 8종
    (상품별 용량 상이) ※롯데 빈츠 부여알밤 外 ※점별 운영상품 상이
    각1,580~7,980원

    각 2,500원할인
    슈퍼너츠 피넛버터 2종
    (각 460g, 스무스/크런치)
    각11,900원

    L.POINT 5,000원
    종근당건강
    락토핏 골드
    90포(2g×90포)
    17,900원

    엘지생활건강
    3만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 "ⓩ"을 확인하세요
    ※일부품목 상품권 증정 행사 제외

    홈스타 맥스프레시 4종(각 900ml)
    ※욕실용/곰팡이/주방용/착붙는락스
    ※1개 구매시 각7,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각3,950원

    아우라 퍼퓸 리필
    (각 1.7L, 로즈/베르가못)
    ※1개 구매시 각9,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각4,950원

    페리오 토탈7
    인텐스 치약 2종
    (리프레쉬쿨링/프리징쿨링)
    ※점별 운영상품 상이
    ※1개 구매시 각13,980원 ※교차구매 가능
    2개 이상 구매시 1개당 각6,990원

    페리오 이지클리닉 칫솔 2종
    (초극세모/탄력모) ※점별 운영상품 상이
    ※1개 구매시 각8,900원 ※교차구매 가능
    2개 이상 구매시 각4,450원

    엘지생활건강
    클렌징 폼 전품목
    (상품별 상이) ※점별 운영상품 상이
    ※일부 재고소진 품목 제외
    ※일부품목 상품권 증정 행사 제외
    ※1개 구매시 각7,900~13,800원 ※교차구매 가능
    2개 이상 구매시 1개당 각3,950~6,900원

    히말라야 핑크솔트
    바디워시(각 900g)
    ※점별 운영상품 상이
    ※1개 구매시 각14,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각7,450원

    엘라스틴 아미노 프로틴
    클리닉 샴푸/컨디셔너
    (각 855ml, 손상/볼륨)
    ※점별 운영상품 상이
    ※1개 구매시 각13,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각6,950원

    리엔 보윤 샴푸/
    컨디셔너 3종
    (각 1,000ml) ※점별 운영상품 상이
    각9,540원

    헨켈 그린 행사상품
    2만 5천원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " ㉠ "을 확인하세요

    퍼실 그린파워
    주방세제 4종
    (상품별 상이)
    ※레몬그라스/알로에베라
    ※1개 구매시 각9,500원 ※교차구매 가능
    2개 이상 구매시 1개당 각4,750원

    모나리자 행사상품
    2만 5천원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " ㉤ "을 확인하세요

    모나리자
    코튼화이트
    (30m×30롤)
    ※1개 구매시 30,800원
    2개 이상 구매시 1개당 각15,400원

    쏘피 생리대/마미포코 행사상품
    3만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " ㉦ "을 확인하세요

    쏘피 유기농 생리대
    (상품별 상이) ※중/대/슈퍼롱
    ※1개 구매시 각16,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각8,450원

    하기스 기저귀 행사상품
    10만원 이상 구매시
    10,000원
    롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " ㉡ "을 확인하세요

    하기스 맥스드라이/네이처메이드
    19종(상품별 상이) ※점별 운영상품 상이
    ※1개 구매시 각30,600~45,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각19,890~29,840원

    2리터 추가기획 공구핫딜
    순샘 레몬
    (2L+2L)
    7,900원

    2리터 추가기획 공구핫딜
    고농축피죤 기획
    (파우더라일락, 2L+2L)
    13,900원

    공구핫딜 케라시스
    비듬케어 쿨링샴푸
    (각 1,000ml, 딥클린/가려움완화)
    각5,900원

    공구핫딜
    샤워메이트
    플라워퍼퓸
    바디워시 옐로우
    (프리지아&쟈스민향 1,200ml)
    4,900원

    롯데/KB국민/신한/NH농협/삼성카드 10%
    피지오겔/바이오더마/세타필
    더마 스킨케어 5종(상품별 상이)
    ※페이지 하단 카드할인 세부내용 참고 ※점별 운영상품 상이
    각17,010~24,219원

    도브 바디로션 3종
    (각 400ml) ※점별 운영상품 상이
    ※너리시드/셔플바운스/라이트
    ※1개 구매시 각15,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각7,950원

    존슨즈 베이비로션 3종
    (상품별 상이) ※점별 운영상품 상이
    ※핑크 500ml+200ml, 화이트/베드타임 각 500ml
    ※1개 구매시 각17,900~21,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각8,950~10,950원

    크리넥스 마이비데 물티슈 전품목
    (상품별 상이) ※점별 운영상품 상이 ※L.POINT회원에 한함
    각1,813~10,430원

    다우니 세탁세제 4종(각 3.2L)
    ※실내건조/헹굼제로/프레시/라벤더
    ※1개 구매시 각35,900~36,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각17,950~18,450원

    ※정상가란? 2024년 9월 19일(목) ~ 10월 9일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    가을특가 리빙 플렉스

    에브리데이쿡 프라이팬 4종/
    뉴이모션 프라이팬 5종(상품별 상이)
    18,480~39,480원

    3M 다목적 수세미/
    제로스크레치 수세미(각 10입)
    ※3M 상품권 증정행사 미해당 품목
    6,390/8,790원

    유니랩 위생장갑/위생롤백(중/대/특대형)
    2,570~4,180원

    더좋은 부탄가스(4입)
    5,000원

    쿡셀 전상품(상품별 상이)
    ※점별 입점상품 상이 ※행사상품에 한함 ※연출된 이미지 컷입니다
    각31,120~50,240원

    락앤락 김치통 2P기획 4종
    (2.6L/3.6L/4.5L/8L)
    각9,900~21,000원

    오늘좋은 극세사 누빔패드
    (싱글 110×200cm/퀸 150×220cm)
    ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이
    24,430~27,930원

    오늘좋은 세탁이 편리한 베개
    (40×60cm/50×70cm)
    ※1개 구매시 각 21,900/29,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각10,950/14,950원

    룸바이홈 쿠션/방석
    (상품별 상이) ※룸바이홈 말랑 사각방석 외
    ※입점점에 한함 ※점별 재고상이 ※해당상품에 한함
    10,000/15,000/20,000원

    오늘좋은×공구핫딜
    베이직 거실화(각 2P)
    ※입점점에 한함 ※점별 재고 상이
    각9,900원

    디클린 정수필터 4종(상품별 상이)
    ※입점점에 한함 ※점별 재고 상이 ※해당상품에 한함
    7,560~18,610원

    파크론 고급 발코니매트(2P, 딥그레이)/
    파크론 실키 놀이방 매트(심플스퀘어)
    ※150×200cm ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이
    37,520/39,920원

    3M 해당상품
    2만5천원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※'도와드리겠습니다'센터 증정
    ※구분자 " M "를 확인하세요

    3M 리싸이클
    정전기 청소포(각 60매)
    (표준형/대형) ※입점점에 한함
    ※해당상품에 한함 ※점별 재고 상이
    8,680/9,910원

    오늘좋은 4륜
    우드탑 테이블 카트
    (42×37×50~100cm)
    ※입점점에 한함
    36,720원

    공구핫딜 절전형
    멀티탭 1+1기획
    (3구 2M)
    ※입점점에 한함 ※점별 재고 상이
    19,900원

    엑스가드 레저소품(상품별 상이)
    ※귀걸이 멀티프, 트레이닝 장갑 外
    5,900~17,900원

    오늘좋은 히트 동내의(상품별 상이)
    7,900~15,900원

    트래커 여행가방(기내용 16/20인치, 화물용 24/28인치)
    ㆍ확장지퍼로 수납력 확대!
    ㆍ 더블 4휠 바퀴 장착으로 이동시 저소음!
    59,000~99,000원

    허밋 원터치 텐트
    (2~3인용/3~4인용) ※입점점에 한함
    39,900/49,900원

    토이저러스 인기완구 L.POINT 최대 20%
    ※기간 : 10/1(화)~10/16(수)

    스위치 슈퍼마리오브라더스
    원더 外 9종(전체 이용가)
    ※입점점에 한함 ※점별 재고 상이
    각54,800원

    헬로카봇 80종
    (상품별 상이)
    각16,000~150,400원

    반려동물 식/용품 해당 상품 최대 30%
    ※기간 : 10/1(화)~10/16(수)

    콜리올리
    콜라겐 시리즈 간식
    (상품별 상이) ※점별 재고 상이
    2,723~3,913원

    콜리올리 방석/하우스 4종
    (상품별 상이) ※점별 재고 상이
    18,323~31,920원

    제타플렉스 밀 솔루션
    오늘 뭐 먹지?
    간편하게 밀키트로, 오늘의 저녁메뉴
    롯데/신한/KB국민/NH농협/삼성카드 구매시 50% 할인!
    5천원 롯데상품권 증정!
    기간 : 10/1(화)~10/16(수)

    혜택1
    냉동밀키트&냉동밥면
    롯데/신한/KB국민/NH농협/삼성카드 2개 이상 구매시 50% 할인
    ※교차구매 가능 ※PB/기획상품 제외
    ※데일리 밀 솔루션 존 상품에 한함
    ※페이지 하단 카드할인 세부내용 참고

    혜택2
    Daily Meal Solution
    전품목(밀키트, 밥/면, 반찬, 안주류)
    롯데/신한/KB국민/NH농협/삼성카드 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※'도와드리겠습니다'센터 증정
    ※구분자 "★"를 확인하세요
    ※데일리 밀 솔루션 존 상품에 한함
    ※페이지 하단 카드할인 세부내용 참고

    요리하다 인기 먹거리 최대 2천원 할인!

    요리하다 꽃게탕(660g) 11,900원

    요리하다×두끼 즉석떡볶이/
    마라떡볶이(560g/520g)
    각4,990원

    오늘좋은 오리지널
    &카라멜 팝콘(280g)
    2,990원

    오늘좋은×고래사
    종합모둠어묵탕(700g)
    6,990원

    ※정상가란? 2024년 9월 19일(목) ~ 10월 9일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
  </div>
  `,
];

const zettaSeoulData = [
  `
  <h1>LOTTE Mart ZETTAPLEX</h1>
  
  <div>
    고객 여러분의 밥상물가를 지킬 10대 아이템 미션 물가를 잡아라
    롯데마트&롯데슈퍼가 물가안정에 앞장섭니다.
    ※기간 : 10월 10일 목요일부터 10월 16일 수요일까지 (비신선식품 적용은 10월 1일 화요일부터 10월 16일 수요일까지)

    서해안 꽃게(100g/국산)
    1,113원

    GAP 친환경
    느타리 버섯(500g/팩/국산)
    1,990원

    제주 흑돼지 블랙페스타
    L.POINT 50%
    요리하다 제주 흑돼지 불고기
    (각 1kg/냉장/원산지 별도표기)
    ※간장/고추장

    1+1 풀무원 서울라면/서울짜장
    (각 4개입) ※교차구매 가능
    각5,450원

    1+1 떠먹는 요구르트 30종
    (각 80g×4입/각 85g×4입)
    ※빙그레/풀무원/남양/매일/일동 外
    ※동일 브랜드 內 동일 가격 간 교차구매 가능
    3,780~6,480원

    공구핫딜
    구운 아몬드/마카다미아
    (800g/350g, 원산지 별도표기)
    ※조기 품절될 수 있습니다
    각9,990원

    1+1 녹차원 꿀 유자차/
    레몬생강차 外 3종
    (각 1kg) ※교차구매 가능
    각9,900원

    1+1 삼진어묵 시원한 모듬어묵/
    쫄깃한 야채사각(456g/200g)
    ※동일 상품에 한함
    7,480/3,280원

    1+1 테크 실내건조 베이킹+구연산
    (각 3.4L, 일반/드럼) ※교차구매 가능
    각19,800원

    롯데/KB국민/신한/NH농협/삼성카드 2개 이상 50%
    잘풀리는집 굿딜
    3겹 화장지(30m×30롤)
    ※페이지 하단 카드할인 세부내용 참고
    ※1개 구매시 27,800원
    2개 이상 구매시 1개당 각13,900원

    공구핫딜 공동구매 가계절약
    공구핫딜은 롯데마트와 슈퍼가
    공동 기획한 알뜰 한정 상품 입니다

    공구핫딜 해태 홈런볼
    15% 중량업(148g)
    3,280원

    공구핫딜 칠성사이다(190ml×30입) 14,490 개당 483원
    공구핫딜 코카콜라(215ml×30입) 18,360원 개당 612원

    공구핫딜
    하림 치킨너겟
    증량기획(480g+300g)
    9,980원

    10+2 기획 공구핫딜 고래사
    꼬치어묵 증량 기획(526g)
    6,940원

    공구핫딜 한성
    와일드크래미(180g×3)
    6,980원

    공구핫딜 롯데
    비엔나소시지(600g)
    7,980원

    공구핫딜 뉴트리원
    이뮨 플러스 7+2
    (21.5g×9병)
    13,900원

    공구핫딜 2080
    로즈핑크 치약 기획
    (150g×4입)
    6,900원

    공구핫딜 퍼실 파워젤/
    실내건조 기획(각 2.7L+2.2L)
    각24,990원

    공구핫딜 실속형 종이컵(600P)/
    테이크아웃 종이컵(300P)
    9,900/15,900원

    2024 롯데마트가 매주 여러분의 경제적 소비생활을 지원합니다
    이번주 핫프라이스 
    롯데마트 연중 최저가 도전!
    '빨래 고민 해결사' 1개당 400원대!
    퍼실 디스크 4종(각 26개입)
    ※파워/라벤더/실내건조/컬러
    ※연중기간 : 23년 9월 ~ 24년 10월
    롯데/KB국민/신한/NH농협/삼성카드 1+1
    21,900원
    ※기간 : 10/10(목) ~ 10/16(수) ※교차구매 가능
    ※점별 운영상품 상이 ※페이지 하단 카드할인 세부내용 참고

    ※정상가란? 2024년 9월 19일(목) ~ 10월 9일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    와규 블랙페스타 청정와규 전품목 롯데/신한카드 결제시 최대 50%
    (100g/냉장/호주산) ※조기 품절될 수 있습니다
    ※페이지 하단 카드할인 세부내용 참고

    한우 국거리/불고기(1등급)
    (각 100g/냉장/국내산 한우고기)
    L.POINT 40%

    L.POINT 50% 삼겹살/목심
    (각 100g/냉장/캐나다산)
    ※조기 품절될 수 있습니다
    ※일부점 제외
    990원
    ※기간 : 10/10(목)~10/13(일), 4일간
    ※1인 2팩 구매한정

    L.POINT 5,000원 깨끗한 농가에서
    직접 키운 자이언트
    토종닭 백숙/볶음탕용
    (각 1.5kg/1.2kg, 냉장, 국내산)
    각14,900원

    1+1
    동원 간편한끼 훈제연어
    (150g/냉장/연어 : 칠레산)
    11,900원

    수산물 구매금액의 1%가
    지속가능한 어업활동에
    기부됩니다

    고등어(2마리/생물/국산)
    ※산지 시황에 따라 조기 품절될 수 있습니다
    6,980원

    L.POINT 30% 통영
    홍가리비
    (100g/국산)
    623원

    L.POINT 50% 코주부 징기스칸 육포
    순한맛/매운맛
    (각 130g/원산지 별도표기)
    각 7,450원

    롯데/KB국민/신한/NH농협/삼성카드 5,000원 할인
    해남절임 배추(20kg/국산)
    ※기간 : 10/7(월)~10/13(일), 7일간
    ※한정수량 소진시 카드할인 종료될 수 있습니다
    ※ 페이지 하단 카드할인 세부내용 참고
    39,900원

    친환경 어린잎채소(90g×2팩/국산)
    2,990원

    온가족 인삼(난발 500g/팩/국산)/
    유명산지 금산인삼(50g/팩/국산)
    17,900/4,990원

    바나나
    (1.4kg 내외/송이/필리핀산)
    3,990원

    2송이 이상 각 1,000원 할인
    비파괴 당도선별
    샤인머스캣(대/송이/국산)
    2송이 이상 구매시 1송이당 각4,990원
    ※1송이 구매시 5,990원

    제주 GAP 하우스 감귤(1.4kg/팩/국산)/
    가을에 먹는 제주 하우스 감귤
    (2kg/박스/국산)
    9,990/14,990원

    청도 홍시
    (10~12입/팩/국산)
    8,990원

    L.POINT 30% ALL NEW 새우초밥
    (20입/1팩/원산지 별도표기)
    13,993원

    꿀간장/꿀사과 닭강정
    (각 1팩/원산지 별도표기)
    각7,990원

    더 커진 깐쇼새우/
    크리스피 허니 쉬림프
    (각 1팩/원산지 별도표기)
    각6,990원

    L.POINT 20% 비빔밥 3종
    (각 1팩/원산지 별도표기)
    ※강된장제육,불고기듬뿍,고추장불고기
    각4,792원

    1,500원 할인 부드러운 호밀빵
    (8입/1팩/원산지 별도표기)
    ※베이커리팩토리/쁘띠르뽀미에 입점점에 한함
    5,900원

    하나사면 하나 더 1+1

    1+1 동원 통그릴
    부어스트(500g) 8,980원

    1+1 CJ 맥스봉 닭가슴살(420g)/
    동그란 스팸(160g) ※동일상품에 한함
    8,480/4,980원

    1+1 하림 텐더스틱
    (550g) 11,480원

    1+1 마니커 소금구이
    통날개구이(400g)
    11,980원

    1+1 청정원 맛선생 국물내기
    한알 4종(상품별 규격 상이)
    ※멸치디포리/야채/사골/황태 ※교차구매 가능
    각9,900원

    1+1 하림 더미식 상온 국물요리 6종
    (부대찌개 外 5종, 각 350g) ※교차구매 가능
    각5,980원

    1+1 동원 양반 용기죽 6종(상품별 상이) ※교차구매 가능
    각3,980원

    1+1 환절기 기능성 유아음료 16종
    (상품별 규격 상이) ※동일 브랜드/동일 가격 교차구매 가능
    1,500~4,900원

    1+1 16입 두유 전품목
    (각 190ml×16입)
    ※동일 시리즈 상품간 교차구매 가능
    14,900~20,000원

    1+1 맥심 TOP 마스터라떼 外 4종(각 275ml)
    ※스위트아메리카노/더블랙/스모키라떼/스모키 블랙 ※교차구매 가능
    각2,380원

    1+1 청우 찰떡쿠키 오리지널(258g)
    5,690원

    1+1 삼립 누네띠네
    오리지널(60g) 2,980원

    2개 이상 구매시 더 싸게

    2개 이상 각 1,000원 할인 CJ 비비고 통새우만두/
    쌀떡볶이(상품별 규격 상이)
    2개 이상 구매시 1개당 각5,980원
    ※1개 구매시 각 6,980원 ※교차구매 가능

    2개 이상 구매시 각 1,980원 할인
    풀무원 냉장면 6종
    (각 2인/상품별 용량 상이)
    ※우동/수제비/도삭면
    2개 이상 구매시 1개당 각5,000원
    ※1개 구매시 각 6,980원 ※교차구매 가능

    2개 이상 구매시 각 3,980원 할인
    풀무원 노엣지피자/
    핫도그 6종(상품별 상이)
    2개 이상 구매시 1개당 각5,000원
    ※1개 구매시 각 8,980원 ※교차구매 가능

    2개 이상 10%
    농심 봉지라면
    전품목 42종(상품별 규격 상이)
    ※올리브 짜파게티 5개입 外 41종
    2개 이상 구매시 1개당 각1,422~14,040원
    ※1개 구매시 각 1,580~15,600원 ※교차구매 가능

    2개 이상 50%
    샘표 고추장/된장/쌈장
    전품목 11종(상품별 용량 상이)
    2개 이상 구매시 1개당 각1,245~16,490원
    ※1개 구매시 각 2,490~32,980원 ※교차구매 가능

    2개 이상 20%
    풀무원 다논
    그릭 플레인 外 2종(각 90g×8입)
    2개 이상 구매시 1개당 각5,584원
    ※1개 구매시 각 6,980원 ※교차구매 가능

    2개 이상 50%
    매일 뼈로가는 칼슘치즈(270g)
    2개 이상 구매시 1개당 각4,990원
    ※1개 구매시 9,980원

    2개 이상 10%
    켈로그 그래놀라 5종
    (상품별 용량 상이)
    ※켈로그 크랜베리 그래놀라 外
    2개 이상 구매시 1개당 각5,922원
    ※1개 구매시 각 6,580원 ※교차구매 가능

    2개 이상 20%
    제주 삼다수 그린
    (2L×6입/500ml×20입) ※제주점 제외
    2개 이상 구매시 1개당 각5,184/7,680원
    ※1개 구매시 각 6,480/9,600원 ※교차구매 가능

    ※정상가란? 2024년 9월 19일(목) ~ 10월 9일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    동원 해당상품
    2개 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※'도와드리겠습니다'센터 증정
    ※구분자 "㉢"를 확인하세요

    동원 치킨 5종(상품별 상이)
    ※순살/양념순살/할라피뇨/스윗허니/치킨봉
    각8,980원

    오뚜기 XO교자 3종
    (상품별 상이)
    ※새우&홍게살/고기/김치
    각8,980원

    CJ 더건강한
    안심비엔나
    (200g×2)
    7,480원

    사조대림 잠룽 부어스트
    (705g)
    8,980원

    풀무원
    국물떡볶이(423.5g)
    3,280원

    CJ 가쓰오
    우동(4인)
    8,980원

    롯데 식사이론 만두 3종(각 700g)
    ※대파고기/단호박닭가슴살/메밀부추
    각8,480원

    L.POINT 4,500원
    동원 살코기 마일드 참치기획
    (90g×3입+90g×3입)
    9,980원

    L.POINT 40%
    하인즈 파스타소스 5종(각 350g)
    ※베이컨 토마토 外 4종 ※점별 운영상품 상이
    각3,588~3,888원

    L.POINT 각 1,000원
    남양 마시는 불가리스
    사과 外 4종(각 150ml×8입)
    각8,980원

    4입기획 개당 1,470원
    공구핫딜 팜앤홈
    제주감귤(1.2L×4입)
    5,880원

    16+8입 기획 개당 562원
    공구핫딜 베지밀 검은참깨
    고칼슘두유(190ml×16+8입)
    13,480원

    동원 소와나무
    순수우유 기획
    (900ml×2입)
    3,980원

    롯데마트 단독
    매일 바이오 그릭
    ×그래놀라 기획(400g×2입)
    8,980원

    빅사이즈 27입 공구핫딜 롯데 초코파이
    (40g×27입) ※점별 운영상품 상이
    8,880원

    켈로그 프링글스
    미니캔 번들
    (636g/오리지날 6입, 양파맛 6입)
    ※점별 운영상품 상이
    11,980원

    롯데 ABC 초콜릿(187g)
    4,480원

    롯데 부여알밤 과자/디저트빵 8종
    (상품별 용량 상이) ※롯데 빈츠 부여알밤 外 ※점별 운영상품 상이
    각1,580~7,980원

    각 2,500원할인
    슈퍼너츠 피넛버터 2종
    (각 460g, 스무스/크런치)
    각11,900원

    L.POINT 5,000원
    종근당건강
    락토핏 골드
    90포(2g×90포)
    17,900원

    엘지생활건강
    3만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 "ⓩ"을 확인하세요
    ※일부품목 상품권 증정 행사 제외

    홈스타 맥스프레시 4종(각 900ml)
    ※욕실용/곰팡이/주방용/착붙는락스
    ※1개 구매시 각7,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각3,950원

    아우라 퍼퓸 리필
    (각 1.7L, 로즈/베르가못)
    ※1개 구매시 각9,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각4,950원

    페리오 토탈7
    인텐스 치약 2종
    (리프레쉬쿨링/프리징쿨링)
    ※점별 운영상품 상이
    ※1개 구매시 각13,980원 ※교차구매 가능
    2개 이상 구매시 1개당 각6,990원

    페리오 이지클리닉 칫솔 2종
    (초극세모/탄력모) ※점별 운영상품 상이
    ※1개 구매시 각8,900원 ※교차구매 가능
    2개 이상 구매시 각4,450원

    엘지생활건강
    클렌징 폼 전품목
    (상품별 상이) ※점별 운영상품 상이
    ※일부 재고소진 품목 제외
    ※일부품목 상품권 증정 행사 제외
    ※1개 구매시 각7,900~13,800원 ※교차구매 가능
    2개 이상 구매시 1개당 각3,950~6,900원

    히말라야 핑크솔트
    바디워시(각 900g)
    ※점별 운영상품 상이
    ※1개 구매시 각14,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각7,450원

    엘라스틴 아미노 프로틴
    클리닉 샴푸/컨디셔너
    (각 855ml, 손상/볼륨)
    ※점별 운영상품 상이
    ※1개 구매시 각13,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각6,950원

    리엔 보윤 샴푸/
    컨디셔너 3종
    (각 1,000ml) ※점별 운영상품 상이
    각9,540원

    헨켈 그린 행사상품
    2만 5천원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " ㉠ "을 확인하세요

    퍼실 그린파워
    주방세제 4종
    (상품별 상이)
    ※레몬그라스/알로에베라
    ※1개 구매시 각9,500원 ※교차구매 가능
    2개 이상 구매시 1개당 각4,750원

    모나리자 행사상품
    2만 5천원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " ㉤ "을 확인하세요

    모나리자
    코튼화이트
    (30m×30롤)
    ※1개 구매시 30,800원
    2개 이상 구매시 1개당 각15,400원

    쏘피 생리대/마미포코 행사상품
    3만원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " ㉦ "을 확인하세요

    쏘피 유기농 생리대
    (상품별 상이) ※중/대/슈퍼롱
    ※1개 구매시 각16,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각8,450원

    하기스 기저귀 행사상품
    10만원 이상 구매시
    10,000원
    롯데상품권 증정
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    ※구분자 " ㉡ "을 확인하세요

    하기스 맥스드라이/네이처메이드
    19종(상품별 상이) ※점별 운영상품 상이
    ※1개 구매시 각30,600~45,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각19,890~29,840원

    2리터 추가기획 공구핫딜
    순샘 레몬
    (2L+2L)
    7,900원

    2리터 추가기획 공구핫딜
    고농축피죤 기획
    (파우더라일락, 2L+2L)
    13,900원

    공구핫딜 케라시스
    비듬케어 쿨링샴푸
    (각 1,000ml, 딥클린/가려움완화)
    각5,900원

    공구핫딜
    샤워메이트
    플라워퍼퓸
    바디워시 옐로우
    (프리지아&쟈스민향 1,200ml)
    4,900원

    롯데/KB국민/신한/NH농협/삼성카드 10%
    피지오겔/바이오더마/세타필
    더마 스킨케어 5종(상품별 상이)
    ※페이지 하단 카드할인 세부내용 참고 ※점별 운영상품 상이
    각17,010~24,219원

    도브 바디로션 3종
    (각 400ml) ※점별 운영상품 상이
    ※너리시드/셔플바운스/라이트
    ※1개 구매시 각15,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각7,950원

    존슨즈 베이비로션 3종
    (상품별 상이) ※점별 운영상품 상이
    ※핑크 500ml+200ml, 화이트/베드타임 각 500ml
    ※1개 구매시 각17,900~21,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각8,950~10,950원

    크리넥스 마이비데 물티슈 전품목
    (상품별 상이) ※점별 운영상품 상이 ※L.POINT회원에 한함
    각1,813~10,430원

    다우니 세탁세제 4종(각 3.2L)
    ※실내건조/헹굼제로/프레시/라벤더
    ※1개 구매시 각35,900~36,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각17,950~18,450원

    ※정상가란? 2024년 9월 19일(목) ~ 10월 9일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    가을특가 리빙 플렉스

    에브리데이쿡 프라이팬 4종/
    뉴이모션 프라이팬 5종(상품별 상이)
    18,480~39,480원

    3M 다목적 수세미/
    제로스크레치 수세미(각 10입)
    ※3M 상품권 증정행사 미해당 품목
    6,390/8,790원

    유니랩 위생장갑/위생롤백(중/대/특대형)
    2,570~4,180원

    더좋은 부탄가스(4입)
    5,000원

    쿡셀 전상품(상품별 상이)
    ※점별 입점상품 상이 ※행사상품에 한함 ※연출된 이미지 컷입니다
    각31,120~50,240원

    락앤락 김치통 2P기획 4종
    (2.6L/3.6L/4.5L/8L)
    각9,900~21,000원

    오늘좋은 극세사 누빔패드
    (싱글 110×200cm/퀸 150×220cm)
    ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이
    24,430~27,930원

    오늘좋은 세탁이 편리한 베개
    (40×60cm/50×70cm)
    ※1개 구매시 각 21,900/29,900원 ※교차구매 가능
    2개 이상 구매시 1개당 각10,950/14,950원

    룸바이홈 쿠션/방석
    (상품별 상이) ※룸바이홈 말랑 사각방석 외
    ※입점점에 한함 ※점별 재고상이 ※해당상품에 한함
    10,000/15,000/20,000원

    오늘좋은×공구핫딜
    베이직 거실화(각 2P)
    ※입점점에 한함 ※점별 재고 상이
    각9,900원

    디클린 정수필터 4종(상품별 상이)
    ※입점점에 한함 ※점별 재고 상이 ※해당상품에 한함
    7,560~18,610원

    파크론 고급 발코니매트(2P, 딥그레이)/
    파크론 실키 놀이방 매트(심플스퀘어)
    ※150×200cm ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이
    37,520/39,920원

    3M 해당상품
    2만5천원 이상 구매시
    5,000원
    롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※'도와드리겠습니다'센터 증정
    ※구분자 " M "를 확인하세요

    3M 리싸이클
    정전기 청소포(각 60매)
    (표준형/대형) ※입점점에 한함
    ※해당상품에 한함 ※점별 재고 상이
    8,680/9,910원

    오늘좋은 4륜
    우드탑 테이블 카트
    (42×37×50~100cm)
    ※입점점에 한함
    36,720원

    공구핫딜 절전형
    멀티탭 1+1기획
    (3구 2M)
    ※입점점에 한함 ※점별 재고 상이
    19,900원

    엑스가드 레저소품(상품별 상이)
    ※귀걸이 멀티프, 트레이닝 장갑 外
    5,900~17,900원

    오늘좋은 히트 동내의(상품별 상이)
    7,900~15,900원

    트래커 여행가방(기내용 16/20인치, 화물용 24/28인치)
    ㆍ확장지퍼로 수납력 확대!
    ㆍ 더블 4휠 바퀴 장착으로 이동시 저소음!
    59,000~99,000원

    허밋 원터치 텐트
    (2~3인용/3~4인용) ※입점점에 한함
    39,900/49,900원

    토이저러스 인기완구 L.POINT 최대 20% 
    ※기간 : 10/1(화)~10/16(수)

    스위치 슈퍼마리오브라더스
    원더 外 9종(전체 이용가)
    ※입점점에 한함 ※점별 재고 상이
    각54,800원

    헬로카봇 80종
    (상품별 상이)
    각16,000~150,400원

    콜리올리 반려동물 식/용품 해당상품 최대 30% 
    ※기간 : 10/1(화)~10/16(수)

    콜리올리
    콜라겐 시리즈 간식
    (상품별 상이) ※점별 재고 상이
    2,723~3,913원

    콜리올리 방석/하우스 4종
    (상품별 상이) ※점별 재고 상이
    18,323~31,920원

    세계맥주 골라담기
    (38종/상품별 용량 상이)
    6캔 구매시 12,000원
    ※1캔 구매시 3,000원 ※4캔 구매시 9,000원

    코젤 보드게임 패키지
    (500ml×6)
    15,980원

    요리하다
    온가족 먹거리 추천!

    요리하다 허니
    바사삭치킨(375g)
    5,990원

    요리하다×두끼 즉석떡볶이/
    마라떡볶이(560g/520g)
    각4,990원

    요리하다 꽃게탕(660g)
    11,900원

    요리하다 정통 가쓰오우동
    (484g)
    4,990원

    오늘 가장 좋은 선택 오늘좋은
    좋은 상품을 최적의 가격에, 롯데전문MD.롯데중앙연구소 PB개발 노하우로 만든 대표 브랜드

    오늘좋은 오리지널
    &카라멜 팝콘(280g)
    2,990원

    오늘좋은 버터갈릭 새우칩/
    블랙패퍼쉬림프 새우칩(각 155g)
    각2,000원

    오늘좋은 먹태칩(50g)/
    옥수수 똘뱅이(195g)
    1,000/2,000원

    오늘좋은×고래사
    종합모둠어묵탕(700g)
    6,990원

    ※정상가란? 2024년 9월 19일(목) ~ 10월 9일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함


  </div>
  `,
];

const fillZero = (width, str) => {
  return str.length >= width
    ? str
    : new Array(width - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
};

const getAllStoreData = (type) => {
  const dataArray = [];
  allStoreData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/${type === "ZETTA" ? "jamsil_" : ""}${fillZero(
        2,
        String(i + 1)
      )}.jpg`,
      title: `${type === "ZETTA" ? "제타플렉스" : "롯데마트"} 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getZettaData = () => {
  const dataArray = [];
  zettaData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/jamsil_${fillZero(2, String(i + 1))}.jpg`,
      title: `제타플렉스 잠실점 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getZettaSeoulData = () => {
  const dataArray = [];
  zettaSeoulData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/seoul_${fillZero(2, String(i + 1))}.jpg`,
      title: `제타플렉스 서울역점 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const imgPath = "images/202410_2";
const category = [];

const data = {
  L201: {
    title: "스마트전단지-전점201",
    category: category,
    type: "HYPER",
    isFooter: true,
    imgPath: imgPath,
    contents: getAllStoreData(""),
  },
  L202: {
    title: "스마트전단지-전점202",
    category: category,
    type: "HYPER",
    isFooter: true,
    imgPath: imgPath,
    contents: getAllStoreData(""),
  },
  L203: {
    title: "스마트전단지-제타플렉스 잠실점203",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaData(""),
  },
  L204: {
    title: "스마트전단지-제타플렉스 잠실점204",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaData(""),
  },
  L205: {
    title: "스마트전단지-제타플렉스 서울역점205",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaSeoulData(""),
  },
  L206: {
    title: "스마트전단지-제타플렉스 서울역점206",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaSeoulData(""),
  },
};

export default data;
